import React from 'react'

import { PageLayout } from 'components/templates/PageLayout'
import { AnalyseTabs } from 'components/templates/AnalyseTabs'
import { AnalyseFilters } from 'components/sections/Analyse/AnalyseFilters'

export const AnalyseLayout: React.FC = (props) => {
  return (
    <PageLayout Menu={<AnalyseTabs />}>
      <AnalyseFilters />

      {props.children}
    </PageLayout>
  )
}
