import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'

import { getReady } from 'store/api'

const ONE_DAY = 86400000

type PrefOptions = {
  m_Item1: number
  m_Item2: string
}[]

const mapKeys = (options: PrefOptions) =>
  options.map((option) => ({ label: option.m_Item2, value: option.m_Item1 }))

export const usePreferencesReferentiel = () => {
  const ready = useSelector(getReady)

  const { data: nrjUnits } = useQuery<PrefOptions, AnyQueryKey>(
    ready && ['nrjUnitsRef'],
    () =>
      superFetch({
        url: `referentiel/nrjunites`,
      }),
    { staleTime: ONE_DAY },
  )

  const { data: devises } = useQuery<PrefOptions, AnyQueryKey>(
    ready && ['devisesRef'],
    () =>
      superFetch({
        url: `referentiel/devises`,
      }),
    { staleTime: ONE_DAY },
  )

  return {
    nrjUnits: nrjUnits ? mapKeys(nrjUnits) : [],
    devises: devises ? mapKeys(devises) : [],
  }
}

interface Preferences {
  CurrencyUI: number
  EnergyUnitUI: number
}

export const usePreferences = () => {
  const ready = useSelector(getReady)

  const { data } = useQuery<Preferences, AnyQueryKey>(ready && ['preferences'], () =>
    superFetch({
      url: `utilisateurs/preferences`,
    }),
  )

  return data
}

type PatchPayload = {
  op: string
  path: string
  value: number
}[]

export const useUpdatePreferences = () => {
  return useMutation<undefined, PatchPayload>(
    (payload) =>
      superFetch({
        url: `/utilisateurs/preferences`,
        method: 'PATCH',
        body: payload,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['preferences'], { force: true })
      },
    },
  )
}
