import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'

import { ErrorBoundary, Loader } from 'components/atoms'
import { SuiviMensuelTable } from 'components/sections/Analyse/SuiviMensuelTable'
import { getPerimetre } from 'store/analyse'

export const AnalysePageSuivi: React.FC = () => {
  const perimetre = useSelector(getPerimetre)

  if (!perimetre || perimetre.length === 0) return null

  return (
    <div css={{ position: 'relative', minHeight: 100 }}>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <SuiviMensuelTable />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}
