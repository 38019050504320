import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Select } from 'components/atoms'
import { useCountryOptions } from 'api'
import { getCountry, setCountry } from 'store/analyse'

export const CountrySelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const countryOptions = useCountryOptions()
  const country = useSelector(getCountry)

  const allOptions = [
    {
      value: null,
      label: t('global.all'),
    },
    ...countryOptions,
  ]

  return (
    <Select
      options={allOptions}
      value={country}
      onChange={(id) => dispatch(setCountry(id))}
      label={t('global.country')}
      inline
      css={{ maxWidth: 300 }}
    />
  )
}
