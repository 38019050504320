import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import moment from 'moment'

type Date = string | null

interface State {
  tabIndex: number
  startDate: Date
  endDate: Date
  energy: number
  aggregation: number
  natures: number[]
  indicators: number[]
  perimetre: number[] | null
  ecart: number
  ecartFilter: boolean
  ecartPct: number
  country: number | null
}

const slice = createSlice({
  name: 'selection',
  initialState: {
    tabIndex: 0,
    startDate: moment()
      .subtract(1, 'month')
      .startOf('year')
      .format(),
    endDate: moment()
      .subtract(1, 'month')
      .startOf('month')
      .format(),
    energy: 1,
    aggregation: 2,
    natures: [0, 5],
    indicators: [0, 1, 3],
    perimetre: null,
    ecart: 0,
    ecartFilter: false,
    ecartPct: 5,
    country: null,
  } as State,
  reducers: {
    setTabIndex: (state, action: PayloadAction<number>) => ({
      ...state,
      tabIndex: action.payload,
    }),
    setStartDate: (state, action: PayloadAction<Date>) => ({
      ...state,
      startDate: action.payload,
    }),
    setEndDate: (state, action: PayloadAction<Date>) => ({
      ...state,
      endDate: action.payload,
    }),
    setEnergy: (state, action: PayloadAction<number>) => ({
      ...state,
      energy: action.payload,
    }),
    setAggregation: (state, action: PayloadAction<number>) => ({
      ...state,
      aggregation: action.payload,
    }),
    setNatures: (state, action: PayloadAction<number[]>) => ({
      ...state,
      natures: action.payload,
    }),
    setIndicators: (state, action: PayloadAction<number[]>) => ({
      ...state,
      indicators: action.payload,
    }),
    setPerimetre: (state, action: PayloadAction<number[]>) => ({
      ...state,
      perimetre: action.payload,
    }),
    setEcart: (state, action: PayloadAction<number>) => ({
      ...state,
      ecart: action.payload,
    }),
    setEcartFilter: (state, action: PayloadAction<boolean>) => ({
      ...state,
      ecartFilter: action.payload,
    }),
    setEcartPct: (state, action: PayloadAction<number>) => ({
      ...state,
      ecartPct: action.payload,
    }),
    setCountry: (state, action: PayloadAction<number>) => ({
      ...state,
      country: action.payload,
    }),
  },
})

export const {
  reducer: analyse,
  actions: {
    setTabIndex,
    setStartDate,
    setEndDate,
    setEnergy,
    setAggregation,
    setNatures,
    setIndicators,
    setPerimetre,
    setEcart,
    setEcartFilter,
    setEcartPct,
    setCountry,
  },
} = slice

export const getTabIndex = (state: RootState) => state.analyse.tabIndex
export const getStartDate = (state: RootState) => state.analyse.startDate
export const getEndDate = (state: RootState) => state.analyse.endDate
export const getEnergy = (state: RootState) => state.analyse.energy
export const getAggregation = (state: RootState) => state.analyse.aggregation
export const getNatures = (state: RootState) => state.analyse.natures
export const getIndicators = (state: RootState) => state.analyse.indicators
export const getPerimetre = (state: RootState) => state.analyse.perimetre
export const getEcart = (state: RootState) => state.analyse.ecart
export const getEcartFilter = (state: RootState) => state.analyse.ecartFilter
export const getEcartPct = (state: RootState) => state.analyse.ecartPct
export const getCountry = (state: RootState) => state.analyse.country
