import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useOrganisation } from 'api'
import { getPerimetre, setPerimetre } from 'store/analyse'
import { TreeSelect } from './TreeSelect'
import { CollapseRow } from './CollapseRow'
import { createFlatTree } from './createTree'

export const Perimetre: React.FC = () => {
  const dispatch = useDispatch()
  const values = useSelector(getPerimetre)
  const handleChange = React.useCallback((p) => dispatch(setPerimetre(p)), [dispatch])

  const orga = useOrganisation()
  const tree = React.useMemo(() => (orga ? createFlatTree(orga) : null), [orga])

  if (tree === null || values === null) return null

  return (
    <TreeSelect
      tree={tree}
      values={values}
      onChange={handleChange}
      nodeHeight={55}
      leafHeight={38}
      checkedOnly
      Row={CollapseRow}
      isOpenByDefault={false}
    />
  )
}
