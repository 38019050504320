import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { queryCache } from 'react-query'
import { useTranslation } from 'react-i18next'

import { getBudgetId } from 'store/budget'
import { useBudgets } from 'api'
import { Select } from 'components/atoms'

export const BudgetSelect: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const budgetId = useSelector(getBudgetId)
  const budgets = useBudgets()

  const options = React.useMemo(() => {
    if (!budgets) return []
    return budgets.map(({ Nom, Id }) => ({ value: Id, label: Nom }))
  }, [budgets])

  const invalid = budgetId !== null && !budgets?.some((budget) => budget.Id === budgetId)
  if (invalid) return null

  return (
    <Select
      value={invalid ? null : budgetId}
      onChange={async (value) => {
        await queryCache.setQueryData(['versions', { budgetId }], null)
        history.push(`/budget/${value}`)
      }}
      options={options}
      label={t('budget.budget')}
      error={invalid ? `${t('budget.budget')} ${t('budget.notFound')}` : null}
      inline
    />
  )
}
