const apiDomain = process.env.REACT_APP_API_DOMAIN
const apiVersion = process.env.REACT_APP_API_VERSION
const apiUrlMut = `${apiDomain}/${apiVersion}/`

const apiDomainCar = process.env.REACT_APP_API_DOMAIN_CAR
const apiUrlCar = `${apiDomainCar}/${apiVersion}/`

const apiDomainInvoice = process.env.REACT_APP_API_DOMAIN_INVOICE
export const apiUrlInvoice = `${apiDomainInvoice}/`

export const loginClientId = process.env.REACT_APP_LOGIN_CLIENT_ID
export const reponseType = process.env.REACT_APP_LOGIN_RESPONSE_TYPE
export const scopes = process.env.REACT_APP_LOGIN_SCOPES
export const autorityUrl = process.env.REACT_APP_LOGIN_AUTHORITY_URL
export const postLoginRedirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL
export const silentRedirectUri = process.env.REACT_APP_LOGIN_SILENT_RENEW_URI

export const OidcLogLevel = process.env.REACT_APP_OIDC_LOG_LEVEL

export const apiUrl = (platform = 'MUT') => {
  switch (platform) {
    case 'CAR':
      return apiUrlCar
    case 'MUT':
    default:
      return apiUrlMut
  }
}

export const urls = [apiUrlMut, apiUrlCar, autorityUrl, postLoginRedirectUrl, silentRedirectUri]
