/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Cell,
} from 'recharts'

import { noDecimalFormat } from 'helpers/formatter'
import { useEcarts } from 'api'
import { getEcart } from 'store/analyse'
import { useTheme } from '@emotion/react'

const isValid = (value: any) =>
  value !== null &&
  typeof value === 'number' &&
  ![Number.MAX_VALUE, Number.MIN_VALUE, Infinity, -Infinity].includes(value)

const formatValue = (value: any) => {
  if (!isValid(value)) return '-'
  return noDecimalFormat(value)
}

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (!active) return null
  return (
    <div className="shadow-sm border p-2 bg-white">
      <div>
        <strong>{payload[0].payload.entity}</strong>
      </div>
      <div>
        {payload[0].name} : <strong>{formatValue(payload[0].value)} €</strong>
      </div>
      <div>
        {payload[1].name} : <strong>{formatValue(payload[1].value)} €</strong>
      </div>
    </div>
  )
}

export const ScatterPlots: React.FC<{
  setHovered: (hovered: string) => void
  hovered: string | null
  className?: string
}> = ({ hovered, setHovered, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const ecart = useSelector(getEcart)
  const ecarts = useEcarts() as Ecarts

  const isBudget = [0, 2].includes(ecart)

  const data = React.useMemo(() => {
    if (!ecarts) return null

    return ecarts.LignesEntite.reduce<any>((acc, { Nom, LignesMesure }) => {
      const item = LignesMesure[0].Data[0]
      const vol = Number(isBudget ? item.ImpactVolumeBudget : item.ImpactVolumeN1)
      const price = Number(isBudget ? item.ImpactPrixBudget : item.ImpactPrixN1)
      if (isNaN(vol) || isNaN(price)) return acc
      acc.push({ entity: Nom, vol, price })
      return acc
    }, [])
  }, [ecarts, isBudget])

  if (!ecarts || ecarts.LignesEntite.length === 0) return null

  return (
    <div {...props} className="shadow-sm border p-4 bg-white">
      <ScatterChart
        width={470}
        height={470}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />

        <XAxis
          type="number"
          dataKey="vol"
          label={{
            value: `${t('ecarts.impactVolume')} (${ecarts.Devise})`,
            position: 'bottom',
          }}
          name={`${t('ecarts.impactVolume')}`}
          tick={{ fontSize: 12 }}
        />

        <YAxis
          type="number"
          dataKey="price"
          label={{
            value: `${t('ecarts.impactPrix')} (${ecarts.Devise})`,
            position: 'left',
            angle: -90,
          }}
          name={`${t('ecarts.impactPrix')}`}
          tick={{ fontSize: 12 }}
        />

        <ZAxis range={[40, 40]} />

        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />

        <ReferenceLine x={0} stroke={theme.gold} />
        <ReferenceLine y={0} stroke={theme.gold} />

        <Scatter
          data={data}
          fill={theme.cyan}
          onMouseOver={({ entity }) => {
            setHovered(entity)
          }}
        >
          {data.map((entry: any, index: number) => {
            // const isSelected = hovered === entry.entity
            return <Cell key={`cell-${index}`} />
          })}
        </Scatter>
      </ScatterChart>
    </div>
  )
}
