import React from 'react'
import { CustomInput } from 'reactstrap'
import difference from 'lodash/difference'
import uniq from 'lodash/uniq'
import { FaAngleRight } from 'react-icons/fa'
import { motion } from 'framer-motion'

import { RowProps, NodeProps, LeafProps } from './TreeSelect'
import { useStyle } from 'hooks'

const Leaf: React.FC<LeafProps> = ({ id, name, values, onChange }) => {
  const originalId = Number(id)
  const checked = React.useMemo(() => values.includes(originalId), [originalId, values])

  const handleChange = React.useCallback(() => {
    if (!checked) {
      onChange([...values, originalId])
    } else {
      const i = values.findIndex((value) => value === originalId)
      if (i >= 0) {
        onChange([...values.slice(0, i), ...values.slice(i + 1)])
      }
    }
  }, [checked, onChange, originalId, values])

  const css = useStyle(() => ({
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
    '>span': {
      flexShrink: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '>svg': {
      flexShrink: 0,
    },
    label: {
      whiteSpace: 'nowrap',
    },
  }))

  return (
    <div css={css}>
      <CustomInput
        type="checkbox"
        id={id.toString()}
        label={<span>{name}</span>}
        checked={checked}
        onChange={handleChange}
      />
    </div>
  )
}

const Node: React.FC<NodeProps> = ({ id, values, name, isOpen, toggle, onChange, nodes }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const leafs: any[] = nodes[id].leafs

  const diff = difference(leafs, values)
  const status = diff.length === 0 ? 1 : diff.length === leafs.length ? 0 : 0.5

  React.useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.indeterminate = status === 0.5
  }, [status])

  const handleChange = React.useCallback(() => {
    onChange(status === 1 ? difference(values, leafs) : uniq([...values, ...leafs]))
  }, [leafs, onChange, status, values])

  const css = useStyle(() => ({
    display: 'flex',
    alignItems: 'center',
    '>*:not(:last-child)': {
      marginRight: 8,
    },
    label: {
      '>span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '>*:not(:last-child)': {
          marginRight: 6,
        },
      },
      '>*:not(:last-child)': {
        marginRight: 6,
      },
    },
  }))

  return (
    <div css={css}>
      <motion.div
        css={{
          lineHeight: 1,
          width: 18,
          height: 18,
        }}
        initial={false}
        animate={{ rotate: isOpen ? 90 : 0.1 }}
        onClick={toggle}
      >
        <FaAngleRight role="button" size={18} css={{ verticalAlign: 'baseline' }} />
      </motion.div>

      <CustomInput
        type="checkbox"
        id={id.toString()}
        label={<span>{name}</span>}
        checked={status === 1}
        onChange={handleChange}
        innerRef={inputRef}
      />
      {/* <Badge color="light">
        {leafs.length - diff.length}/{leafs.length}
      </Badge> */}
    </div>
  )
}

export const TreeRow: React.FC<RowProps> = ({
  data: { id, isLeaf, name, nestingLevel, values, onChange, nodes },
  isOpen,
  style,
  toggle,
}) => {
  const css = useStyle(() => ({
    ...style,
    paddingLeft: 15 + nestingLevel * 15 + (isLeaf ? 30 : 0),
    paddingRight: 15,
    display: 'flex',
    alignItems: 'center',
  }))

  return (
    <div css={css}>
      {isLeaf ? (
        <Leaf {...{ id, name, values, onChange }} />
      ) : (
        <Node {...{ id, name, values, isOpen, toggle, onChange, nodes }} />
      )}
    </div>
  )
}
