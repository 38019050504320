import React from 'react'
import { Button, Spinner, Form } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addNotification } from 'notification'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import flatMap from 'lodash/flatMap'
import range from 'lodash/range'
import { useTranslation } from 'react-i18next'

import { useStyle } from 'hooks'
import { DateField } from 'components/form/DateField'
import { useCreateVersion, useBudgetName, useBudgetDetail } from 'api'
import { getBudgetId } from 'store/budget'
import { dateFormat } from 'helpers/formatter'

export const VersionCreate: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [mutate, { data: version, status }] = useCreateVersion()
  const budgetId = useSelector(getBudgetId)
  const budgetName = useBudgetName()
  const budgetDetail = useBudgetDetail()

  const schema = () =>
    Yup.object().shape({
      date: Yup.string().required(t('global.requiredField')),
    })

  React.useEffect(() => {
    if (!version) return
    setTimeout(done)
    history.push(`/budget/${budgetId}/version/${version.Id}`)
    addNotification({
      type: 'success',
      title: `${t('budget.budget')} ${budgetName}`,
      message: t('createVersion.successMessage', {
        name: moment(version.DateReprevision).format(dateFormat()),
      }),
    })
  }, [budgetId, budgetName, done, history, t, version])

  const style = useStyle(() => ({
    header: {
      marginBottom: '2rem',
    },
    '.fields': {
      width: 200,
    },
    footer: {
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: 0,
      bottom: 0,
      margin: '2rem',
    },
  }))

  const values = {
    date: moment().format(dateFormat()),
  }

  const handleSubmit = React.useCallback(
    async (values, actions) => {
      try {
        if (!budgetDetail) return []

        const m = moment(budgetDetail.Annee, 'YYYY')
        const dates = range(12).map((i) =>
          m
            .clone()
            .add(i, 'months')
            .format(dateFormat()),
        )

        const chrTypes = [3000, 3001, 3002, 3003, 3004]

        const chroniques = flatMap(chrTypes, (ChrType) =>
          budgetDetail?.Perimetre.map(({ id: PeeId, PeeClef }) => ({
            PeeId,
            PeeClef,
            ChrType,
            Data: dates.map((d) => ({ d, v: 0 })),
          })),
        )

        await mutate({
          date: values.date,
          chroniques,
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('createVersion.errorTitle'),
          message: (error as any).toString(),
        })
      } finally {
        actions.setSubmitting(false)
      }
    },
    [budgetDetail, mutate, t],
  )

  return (
    <div css={style}>
      <header>
        <h5>{t('createVersion.title')}</h5>
      </header>

      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <div className="fields">
              <DateField name="date" label="Date" />
            </div>

            <footer>
              <Button disabled={status === 'loading' || props.isSubmitting} type="submit">
                {status === 'loading' && <Spinner size="sm" className="mr-2" />}
                {t('createVersion.submitButton')}
              </Button>
            </footer>
          </Form>
        )}
      </Formik>
    </div>
  )
}
