export const queryConfig = {
  retry: 2,
  staleTime: 2000,
  refetchAllOnWindowFocus: false,
  suspense: true,
}

export * from './referentiel'
export * from './suppliers'
export * from './organisation'
export * from './analyse'
export * from './budgets'
export * from './versions'
export * from './preferences'
