import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Button, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { IoMdSwap } from 'react-icons/io'
import { FaFileExcel } from 'react-icons/fa'

import range from 'lodash/range'
import flatMap from 'lodash/flatMap'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import { getNatures, getIndicators, getStartDate, getEndDate } from 'store/analyse'
import { indicatorsRef, naturesRef } from 'referentiel'
import { useBoolean, useStyle } from 'hooks'
import { noDecimalFormat, dateFormat } from 'helpers/formatter'
import { downloadCSV } from 'helpers/downloadFile'
import { useAtterrissage } from 'api'

const isValid = (value: any) =>
  value !== null &&
  typeof value === 'number' &&
  ![Number.MAX_VALUE, Number.MIN_VALUE, Infinity, -Infinity].includes(value)

const formatValue = (value: any) => {
  if (!isValid(value)) return '-'
  return noDecimalFormat(value)
}

type IndicatorKey =
  | 'Reel'
  | 'Budget'
  | 'ReelN1'
  | 'EcartBudget'
  | 'EcartPctBudget'
  | 'EcartN1'
  | 'EcartPctN1'
  | 'Facture'

const indicatorKeysMap: IndicatorKey[] = [
  'Reel',
  'Budget',
  'ReelN1',
  'EcartBudget',
  'EcartPctBudget',
  'EcartN1',
  'EcartPctN1',
  'Facture',
]

const borderStyle = '1px solid rgb(0,0,0,.1)'
const borderStyleBold = '1px solid rgb(0,0,0,0.3)'

const useShowTotalColumn = () => {
  const startDate = useSelector(getStartDate) as string
  const endDate = useSelector(getEndDate) as string
  const monthCount = moment(endDate).diff(moment(startDate), 'months', true)
  return monthCount > 1
}

const useRevolu = () => {
  const endDate = useSelector(getEndDate) as string
  const revolu = moment().isAfter(
    moment(endDate)
      .endOf('month')
      .add(10, 'days'),
  )

  return revolu
}

export const AtterrissageTable: React.FC = () => {
  const { t } = useTranslation()
  const showTotalColumn = useShowTotalColumn()
  const revolu = useRevolu()

  const selectedNatures = useSelector(getNatures)
  const selectedIndicators = useSelector(getIndicators)
  const atterrissage = useAtterrissage()
  const perimetre = atterrissage?.Total.map(({ Nom }) => Nom) || []

  const [natureFirst, toggleNatureFirst] = useBoolean(false)

  const indicators = indicatorsRef(t).filter((ind) => selectedIndicators.includes(ind.value))
  const natures = naturesRef(t).filter((nat) => selectedNatures.includes(nat.value))

  const rowRef = React.useRef<HTMLTableRowElement>(null)
  const colRef = React.useRef<HTMLTableCellElement>(null)
  const [width, setWidth] = React.useState<number | null>(null)
  const [height, setHeight] = React.useState<number | null>(null)

  React.useEffect(() => {
    const height = rowRef.current?.getBoundingClientRect().height
    if (height) setHeight(height)

    const width = colRef.current?.getBoundingClientRect().width
    if (width) setWidth(width)
  }, [atterrissage, natureFirst])

  const css = useStyle((theme: any) => ({
    maxHeight: 500,
    maxWidth: '100%',
    overflow: 'auto',

    table: {
      backgroundColor: theme.gray100,
      margin: 0,
      borderCollapse: 'separate',
      borderSpacing: 0,
      border: 0,
      fontSize: 13,
      width: 'unset',
      minWidth: '100%',
    },

    'th, td': {
      border: 0,
      borderTop: borderStyle,
      borderLeft: borderStyle,
    },
    th: {
      verticalAlign: 'middle',
    },
    td: {
      textAlign: 'right',
      width: 110,
    },

    // sticky header
    thead: {
      tr: {
        th: {
          backgroundColor: theme.gray100,
          position: 'sticky !important',
          zIndex: 2,
          height: height || 'unset',
          borderBottom: 0,
          textAlign: 'center',
        },
        '&:nth-of-type(1)': {
          th: {
            top: 0,
          },
        },
        '&:nth-of-type(2)': {
          th: {
            top: height || 'unset',
            boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important',
          },
        },
      },
    },

    // sticky aside
    '.sticky': {
      backgroundColor: theme.gray100,
      position: 'sticky !important',
    },
    '.sticky1': {
      left: 0,
      minWidth: width || 'unset',
      maxWidth: width || 'unset',
      borderLeft: borderStyle,
    },
    '.sticky2': {
      left: width || 'unset',
      borderRight: borderStyle,
    },
    'tbody .sticky': {
      zIndex: 1,
    },
    'thead .sticky': {
      zIndex: 3,
      boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important',
    },

    tbody: {
      tr: {
        // stripes
        '&:nth-of-type(odd)': {
          backgroundColor: theme.gray200,
          '.sticky2': {
            backgroundColor: theme.gray200,
          },
        },
        // hover
        '&:hover': {
          backgroundColor: theme.gray300,
          '.sticky2': {
            backgroundColor: theme.gray300,
          },
        },
      },
      th: {
        width: 200,
      },
    },
    '.cell-ok': {
      backgroundColor: 'hsl(1225, 42%, 90%)',
    },
    '.cell-ko': {
      backgroundColor: 'hsl(355, 42%, 90%)',
    },
    [`td:nth-of-type(${indicators.length}n + 1)`]: {
      borderLeft: borderStyleBold,
    },
    [`.tr-indicators th:nth-of-type(${indicators.length}n + 3)`]: {
      borderLeft: borderStyleBold,
    },
    '.th-date': {
      borderLeft: borderStyleBold,
    },
    [`tbody tr:nth-of-type(${natureFirst ? perimetre.length : natures.length}n + 1)`]: {
      'th, td': {
        borderTop: borderStyleBold,
      },
    },
    '.th-entity': {
      borderTop: borderStyleBold,
    },
  }))

  const values = React.useMemo(() => {
    const getValues = (o: LigneEntite[]) =>
      mapValues(keyBy(o, 'Nom'), (o) =>
        (o?.LignesMesure ?? []).reduce((acc, x, i) => ({ ...acc, [i]: x.Data[0] }), {}),
      )

    return {
      ytd: !atterrissage?.YTD ? {} : getValues(atterrissage.YTD),
      dtye: !atterrissage?.DTYE ? {} : getValues(atterrissage.DTYE),
      ...(showTotalColumn && { total: !atterrissage?.Total ? {} : getValues(atterrissage.Total) }),
    }
  }, [atterrissage, showTotalColumn])

  const lineKeys = React.useMemo(
    () =>
      flatMap(Object.keys(values), (part) =>
        indicators.map(({ value }) => ({ part, indicator: indicatorKeysMap[value] })),
      ),
    [indicators, values],
  )

  if (!atterrissage) return null

  const { DTYE, YTD, Total } = atterrissage

  if (
    (!DTYE || DTYE.filter((le) => le.LignesMesure && le.LignesMesure.length > 0).length === 0) &&
    (!YTD || YTD.filter((le) => le.LignesMesure && le.LignesMesure.length > 0).length === 0) &&
    (!Total || Total.filter((le) => le.LignesMesure && le.LignesMesure.length > 0).length === 0)
  )
    return <div>{t('analyse.empty')}</div>

  const start1 =
    (atterrissage.StartYTD && moment(atterrissage.StartYTD).format(dateFormat())) || undefined
  const end1 =
    (atterrissage.EndYTD && moment(atterrissage.EndYTD).format(dateFormat())) || undefined
  const start2 =
    (atterrissage.StartDTYE && moment(atterrissage.StartDTYE).format(dateFormat())) || undefined
  const end2 =
    (atterrissage.EndDTYE && moment(atterrissage.EndDTYE).format(dateFormat())) || undefined

  const exportCSV = () => {
    const indRange = range(indicators.length)

    const header1 = [
      '',
      '',
      ...indRange.map(() =>
        start1 ? t('atterrissage.fromTo', { start: start1, end: end1 }) : '-',
      ),
      ...indRange.map(() =>
        start2 ? t('atterrissage.fromTo', { start: start2, end: end2 }) : '-',
      ),
      ...(showTotalColumn ? [indRange.map(() => 'Total')] : []),
    ]

    const header2 = [
      natureFirst ? t('atterrissage.nature') : t('atterrissage.perimetre'),
      natureFirst ? t('atterrissage.perimetre') : t('atterrissage.nature'),
      ...flatMap(range(showTotalColumn ? 3 : 2), (i) =>
        indicators.map(({ value, label }) =>
          i > 0 && value === 0 && !revolu ? t('atterrissage.prevu') : label,
        ),
      ),
    ]

    const body = natureFirst
      ? flatMap(natures, (nature) =>
          perimetre.map((entity) => [
            `${nature.label} (${
              nature.value === 0 ? atterrissage.UniteEnergie : atterrissage.Devise
            })`,
            `"=""${entity}"""`,
            ...lineKeys.map(({ part, indicator }) => {
              const value = (values as any)?.[part]?.[entity]?.[nature.value]?.[indicator]
              return value ? formatValue(value) : ''
            }),
          ]),
        )
      : flatMap(perimetre, (entity) =>
          natures.map((nature) => [
            `"=""${entity}"""`,
            `${nature.label} (${
              nature.value === 0 ? atterrissage.UniteEnergie : atterrissage.Devise
            })`,
            ...lineKeys.map(({ part, indicator }) => {
              const value = (values as any)?.[part]?.[entity]?.[nature.value]?.[indicator]
              return value ? formatValue(value) : ''
            }),
          ]),
        )

    const content = [header1, header2, ...body].map((row) => row.join(';')).join('\n')
    const date = moment().format('YYYY-MM-DD')
    const filename = `${t('atterrissage.filename')} ${date}.csv`
    downloadCSV(filename, content)
  }

  return (
    <>
      <div className="d-flex justify-content-end my-3">
        <Button
          style={{ color: 'black', background: '#98D2DD', border: '#98D2DD' }}
          onClick={exportCSV}
        >
          <FaFileExcel css={{ marginRight: '0.4rem', marginTop: -3 }} />
          {t('global.exportCsv')}
        </Button>
      </div>

      <div css={css}>
        <Table className="border shadow-sm" bordered size="sm">
          <thead>
            <tr ref={rowRef}>
              <th className="sticky sticky1" colSpan={2} style={{ textAlign: 'center' }}>
                <Button size="sm" color="secondary" outline onClick={toggleNatureFirst}>
                  <IoMdSwap size={18} />
                </Button>
              </th>
              <th colSpan={indicators.length} className="th-date">
                {start1 ? <>{t('atterrissage.fromTo', { start: start1, end: end1 })}</> : <>-</>}
              </th>
              <th colSpan={indicators.length} className="th-date">
                {start2 ? <>{t('atterrissage.fromTo', { start: start2, end: end2 })}</> : <>-</>}
              </th>
              {showTotalColumn && (
                <th colSpan={indicators.length} className="th-date">
                  TOTAL
                </th>
              )}
            </tr>

            <tr className="tr-indicators">
              <th className="sticky sticky1" ref={colRef}>
                {natureFirst ? t('atterrissage.nature') : t('atterrissage.perimetre')}
              </th>
              <th className="sticky sticky2">
                {natureFirst ? t('atterrissage.perimetre') : t('atterrissage.nature')}
              </th>

              {flatMap(range(showTotalColumn ? 3 : 2), (i) =>
                indicators.map(({ value, label }) => {
                  return (
                    <th key={`${value}-${i}`}>
                      {i > 0 && value === 0 && !revolu ? t('atterrissage.prevu') : label}
                    </th>
                  )
                }),
              )}
            </tr>
          </thead>

          <tbody>
            {natureFirst
              ? flatMap(natures, (nature) =>
                  perimetre.map((entity, index) => (
                    <tr key={`${entity}_${nature.label}`}>
                      {index === 0 && (
                        <th className="sticky sticky1" rowSpan={perimetre.length}>
                          {nature.label} (
                          {nature.value === 0 ? atterrissage.UniteEnergie : atterrissage.Devise})
                        </th>
                      )}
                      <th className="sticky sticky2">{entity}</th>

                      {lineKeys.map(({ part, indicator }, i) => {
                        const value = (values as any)?.[part]?.[entity]?.[nature.value]?.[indicator]

                        let className = 'cell'
                        if (
                          typeof value === 'number' &&
                          isValid(value) &&
                          Math.round(value) !== 0 &&
                          ['EcartBudget', 'EcartPctBudget', 'EcartN1', 'EcartPctN1'].includes(
                            indicator,
                          )
                        ) {
                          className = value > 0 ? 'cell-ko' : 'cell-ok'
                        }

                        return (
                          <td key={`${entity}_${nature.label}_${i}`} className={className}>
                            {formatValue(value)}
                          </td>
                        )
                      })}
                    </tr>
                  )),
                )
              : flatMap(perimetre, (entity) =>
                  natures.map((nature, index) => (
                    <tr key={`${entity}_${nature.label}`}>
                      {index === 0 && (
                        <th className="sticky sticky1" rowSpan={natures.length}>
                          {entity}
                        </th>
                      )}
                      <th className="sticky sticky2">
                        {nature.label} (
                        {nature.value === 0 ? atterrissage.UniteEnergie : atterrissage.Devise})
                      </th>

                      {lineKeys.map(({ part, indicator }, i) => {
                        const value = (values as any)?.[part]?.[entity]?.[nature.value]?.[indicator]

                        let className = 'cell'
                        if (
                          typeof value === 'number' &&
                          isValid(value) &&
                          Math.round(value) !== 0 &&
                          ['EcartBudget', 'EcartPctBudget', 'EcartN1', 'EcartPctN1'].includes(
                            indicator,
                          )
                        ) {
                          className = value > 0 ? 'cell-ko' : 'cell-ok'
                        }

                        return (
                          <td key={`${entity}_${nature.label}_${i}`} className={className}>
                            {formatValue(value)}
                          </td>
                        )
                      })}
                    </tr>
                  )),
                )}
          </tbody>
        </Table>
      </div>
    </>
  )
}
