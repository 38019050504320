import Oidc from 'oidc-client'
import { createUserManager } from 'redux-oidc'

import * as env from './helpers/env'

// Paramètres de log du module OIDC
Oidc.Log.logger = console
// NONE, ERROR, WARN, INFO, DEBUG
Oidc.Log.level = Oidc.Log.NONE

// Configuration OIDC
const userManagerConfig = {
  client_id: env.loginClientId,
  redirect_uri: env.postLoginRedirectUrl,
  response_type: env.reponseType,
  scope: env.scopes,
  authority: env.autorityUrl,
  automaticSilentRenew: false,
  // silent_redirect_uri: env.silentRedirectUri,
  filterProtocolClaims: true,
  loadUserInfo: true,
  // silentRequestTimeout: 60_000,
}

const userManager = createUserManager(userManagerConfig)

// When a user logs in successfully or a token is renewed, the `userLoaded`
// event is fired. the `addUserLoaded` method allows to register a callback to
// that event
userManager.events.addUserLoaded(function(loadedUser) {
  // Make some controls after success login

  // Si pas de role alors on cree une structure vide
  if (loadedUser.profile) {
    let roles = []
    switch (typeof loadedUser.profile.role) {
      case undefined:
        roles = []
        break
      case 'string':
        roles = [loadedUser.profile.role]
        break
      default:
        if (Array.isArray(loadedUser.profile.role)) roles = loadedUser.profile.role
        break
    }
    loadedUser.profile.role = roles
  }
})

// Same mechanism for when the automatic renewal of a token fails
userManager.events.addSilentRenewError(function(error) {
  console.error('error while renewing the access token', error)
})

// When the automatic session management feature detects a change in
// the user session state, the `userSignedOut` event is fired.
userManager.events.addUserSignedOut(function() {
  console.debug('The user has signed out')
})

export default userManager
