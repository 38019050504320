import React from 'react'
import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getUorIdMst } from 'store/oidc'
import { getBudgetId } from 'store/budget'
import keyBy from 'lodash/keyBy'

export const useBudgets = () => {
  const ready = useSelector(getReady)

  const { data } = useQuery<Budget[], AnyQueryKey>(ready && ['budgets'], () =>
    superFetch({ url: `budgets` }),
  )

  return data
}

export const useBudgetName = () => {
  const budgetId = useSelector(getBudgetId)
  const budgets = useBudgets()
  return budgets?.find((budget) => budget.Id === budgetId)?.Nom
}

export const useBudgetDetail = () => {
  const ready = useSelector(getReady)
  const budgetId = useSelector(getBudgetId)

  const { data } = useQuery<BudgetDetail, AnyQueryKey>(
    ready && budgetId !== null && ['budget', { budgetId }],
    () => superFetch({ url: `budgets/${budgetId}` }),
  )

  return data
}

export const useBudgetPerimetre = () => {
  const budget = useBudgetDetail()
  return React.useMemo(() => (!budget ? null : keyBy(budget?.Perimetre, 'id')), [budget])
}

export const useCreateBudget = () => {
  const UorIdMst = useSelector(getUorIdMst)

  return useMutation<
    Budget,
    { Date: string; Nom: string; Annee: number; Perimetre: { id: number }[] }
  >(
    ({ Date, Nom, Annee, Perimetre }) =>
      superFetch({
        url: `budgets?datePrev=${Date}`,
        method: 'POST',
        body: { Nom, Annee, UorIdMst, Pas: 3, Perimetre },
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['budgets'], { force: true })
      },
    },
  )
}

export const useUpdateBudget = () => {
  const budgetId = useSelector(getBudgetId)

  return useMutation<
    Budget,
    { id: number; Date: string; Nom: string; Annee: number; Perimetre: { id: number }[] }
  >(
    ({ Date, Nom, Annee, Perimetre }) =>
      superFetch({
        url: `budgets/${budgetId}`,
        method: 'PUT',
        body: { Id: budgetId, Nom, Annee, Pas: 3, Perimetre },
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['budgets'], { force: true })
      },
    },
  )
}

export const useDeleteBudget = () => {
  return useMutation<void, { budgetId: number }>(
    ({ budgetId }) =>
      superFetch({
        url: `budgets/${budgetId}`,
        method: 'DELETE',
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['budgets'], { force: true })
      },
    },
  )
}
