import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useDeleteVersion, useBudgetName, useVersionName } from 'api'
import { getBudgetId, getVersionId, setVersionId } from 'store/budget'
import { addNotification } from 'notification'
import { useStyle } from 'hooks'

export const VersionDelete: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const [mutate, { status }] = useDeleteVersion()
  const history = useHistory()
  const dispatch = useDispatch()
  const budgetId = useSelector(getBudgetId)
  const versionId = useSelector(getVersionId)
  const budgetName = useBudgetName()
  const versionName = useVersionName()

  const handleClick = React.useCallback(async () => {
    if (versionId === null) return null
    try {
      await mutate({ versionId })
      done()
      setTimeout(() => {
        history.push(`/budget/${budgetId}`)
        dispatch(setVersionId(null))
        addNotification({
          type: 'success',
          title: t('deleteVersion.successTitle', { name: budgetName }),
          message: t('deleteVersion.successMessage', { name: versionName?.date }),
        })
      }, 100)
    } catch (error) {
      addNotification({
        type: 'danger',
        title: t('deleteVersion.errorTitle'),
        message: (error as any).toString(),
      })
    }
  }, [budgetId, budgetName, dispatch, done, history, mutate, t, versionId, versionName])

  const style = useStyle(() => ({
    strong: {
      whiteSpace: 'nowrap',
    },
  }))

  if (!versionName) return null

  return (
    <div css={style}>
      <p>
        {t('deleteVersion.p1')} <strong>{versionName.date}</strong> ?
      </p>

      <p>{t('deleteVersion.p2')}</p>

      <div
        css={{
          display: 'flex',
          marginTop: '2rem',
          justifyContent: 'center',
        }}
      >
        <Button
          color="danger"
          disabled={status === 'loading'}
          onClick={handleClick}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {status === 'loading' ? (
            <>
              <Spinner size="sm" style={{ marginRight: '.6rem' }} />
              <span>{t('deleteVersion.progress')}</span>
            </>
          ) : (
            <span>{t('deleteVersion.button')}</span>
          )}
        </Button>
      </div>
    </div>
  )
}
