import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getBudgetId, getVersionId, setBudgetId, setVersionId } from 'store/budget'
import { PageLayout } from 'components/templates/PageLayout'
import { BudgetFilters } from 'components/sections/Budget/BudgetFilters'

export const BudgetLayout: React.FC = (props) => {
  const { budget, version } = useParams<any>()
  const dispatch = useDispatch()
  const selectedBudget = useSelector(getBudgetId)
  const selectedVersion = useSelector(getVersionId)

  React.useEffect(() => {
    if (!budget || selectedBudget === Number(budget)) return
    dispatch(setBudgetId(Number(budget)))
  }, [budget, dispatch, selectedBudget])

  React.useEffect(() => {
    if (!version || selectedVersion === Number(version)) return
    const id = Number(version)
    dispatch(setVersionId(id))
  }, [dispatch, selectedVersion, version])

  return <PageLayout Menu={<BudgetFilters />}>{props.children}</PageLayout>
}
