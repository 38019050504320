import { useQuery, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'

import { usePreferences } from 'api'
import { getReady } from 'store/api'
import {
  getPerimetre,
  getStartDate,
  getEndDate,
  getEnergy,
  getAggregation,
  getEcartFilter,
  getEcartPct,
  getEcart,
  getCountry,
} from 'store/analyse'

export const useSuiviMensuel = () => {
  const ready = useSelector(getReady)
  const PeeIds = useSelector(getPerimetre)
  const Debut = useSelector(getStartDate)
  const Fin = useSelector(getEndDate)
  const Energie = useSelector(getEnergy)
  const Agregation = useSelector(getAggregation)
  const IdPays = useSelector(getCountry)
  const preferences = usePreferences()

  const { data } = useQuery<SuiviMensuel, AnyQueryKey>(
    ready &&
      Debut !== null &&
      Fin !== null &&
      PeeIds !== null &&
      preferences && [
        'suiviMensuel',
        { PeeIds, Debut, Fin, Energie, Agregation, IdPays, preferences },
      ],
    () =>
      superFetch({
        url: `budgets/suivi/mensuel`,
        method: 'POST',
        body: { PeeIds, Debut, Fin, Energie, Agregation, IdPays },
      }),
  )

  return data
}

export const useAtterrissage = () => {
  const ready = useSelector(getReady)
  const PeeIds = useSelector(getPerimetre)
  const Debut = useSelector(getStartDate)
  const Fin = useSelector(getEndDate)
  const Energie = useSelector(getEnergy)
  const Agregation = useSelector(getAggregation)
  const IdPays = useSelector(getCountry)
  const preferences = usePreferences()

  const { data } = useQuery<Atterrissage, AnyQueryKey>(
    ready &&
      Debut !== null &&
      Fin !== null &&
      PeeIds !== null &&
      preferences && [
        'atterrissage',
        { PeeIds, Debut, Fin, Energie, Agregation, IdPays, preferences },
      ],
    () =>
      superFetch({
        url: `budgets/atterrissage`,
        method: 'POST',
        body: { PeeIds, Debut, Fin, Energie, Agregation, IdPays },
      }),
  )

  return data
}

export const useEcarts = () => {
  const ready = useSelector(getReady)

  const PeeIds = useSelector(getPerimetre)
  const Debut = useSelector(getStartDate)
  const Fin = useSelector(getEndDate)
  const Energie = useSelector(getEnergy)
  const Agregation = useSelector(getAggregation)
  const IdPays = useSelector(getCountry)
  const preferences = usePreferences()

  const ecart = useSelector(getEcart)
  const ecartFilter = useSelector(getEcartFilter)
  const ecartPct = useSelector(getEcartPct)

  const { data } = useQuery<Ecarts, AnyQueryKey>(
    ready &&
      Debut !== null &&
      Fin !== null &&
      PeeIds !== null &&
      preferences && ['ecarts', { PeeIds, Debut, Fin, Energie, Agregation, IdPays, preferences }],
    () =>
      superFetch({
        url: `budgets/ecarts`,
        method: 'POST',
        body: { PeeIds, Debut, Fin, Energie, Agregation, IdPays },
      }),
  )

  if (data?.LignesEntite && ecartFilter)
    return {
      ...data,
      LignesEntite: data.LignesEntite.filter((line) => {
        const x = line.LignesMesure[0].Data[0][ecart === 0 ? 'EcartPctBudget' : 'EcartPctN1']
        return x && Math.abs(x) >= ecartPct
      }),
    }

  return data
}

export const useEcartsMensuels = (peeId: number) => {
  const ready = useSelector(getReady)

  const PeeIds = [peeId]
  const Debut = useSelector(getStartDate)
  const Fin = useSelector(getEndDate)
  const Energie = useSelector(getEnergy)
  const Agregation = useSelector(getAggregation)
  const IdPays = useSelector(getCountry)
  const preferences = usePreferences()

  const { data } = useQuery<Ecarts, AnyQueryKey>(
    ready &&
      Debut !== null &&
      Fin !== null &&
      PeeIds !== null &&
      preferences && ['ecarts', { PeeIds, Debut, Fin, Energie, Agregation, IdPays, preferences }],
    () =>
      superFetch({
        url: `budgets/ecartsmensuels`,
        method: 'POST',
        body: { PeeIds, Debut, Fin, Energie, Agregation, IdPays },
      }),
  )

  return data
}
