import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Button } from 'reactstrap'

import { useStyle } from 'hooks'

export const Dialog: React.FC<{
  isOpen: boolean
  close: () => void
  className?: string
}> = ({ isOpen, close, children, ...props }) => {
  const style = useStyle(() => ({
    zIndex: 2000,
    '[data-reach-dialog-content]': {
      boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
      position: 'relative',
      padding: '2rem',
    },
    'button.close': {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: '1rem',
    },
  }))

  return (
    <DialogOverlay css={style} isOpen={isOpen} onDismiss={close}>
      <DialogContent aria-labelledby="new-contract" {...props}>
        {children}
        <Button close onClick={close} />
      </DialogContent>
    </DialogOverlay>
  )
}
