import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

type Id = number | null

interface State {
  budgetId: Id
  versionId: Id
}

const slice = createSlice({
  name: 'selection',
  initialState: {
    budgetId: null,
    versionId: null,
  } as State,
  reducers: {
    setBudgetId: (state, action: PayloadAction<Id>) => ({
      ...state,
      budgetId: action.payload,
      versionId: null,
    }),
    setVersionId: (state, action: PayloadAction<Id>) => ({
      ...state,
      versionId: action.payload,
    }),
  },
})

export const {
  reducer: budget,
  actions: { setBudgetId, setVersionId },
} = slice

export const getBudgetId = (state: RootState) => state.budget.budgetId
export const getVersionId = (state: RootState) => state.budget.versionId
