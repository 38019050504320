import React, { Suspense } from 'react'
import { FaPlus, FaTrashAlt, FaEdit } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Spinner } from 'reactstrap'

import { useStyle, useBoolean } from 'hooks'
import { getBudgetId } from 'store/budget'
import { useBudgets } from 'api'
import { ErrorBoundary, Dialog, Loader } from 'components/atoms'
import { BudgetSelect } from './BudgetSelect'
import { BudgetCreate } from './BudgetCreate'
import { BudgetEdit } from './BudgetEdit'
import { BudgetDelete } from './BudgetDelete'
import { BudgetFiltersVersions } from './BudgetFiltersVersions'

export const BudgetFilters: React.FC = () => {
  const { t } = useTranslation()
  const [isBudgetCreateOpen, toggleBudgetCreate, closeBudgetCreate] = useBoolean(false)
  const [isBudgetEditOpen, toggleBudgetEdit, closeBudgetEdit] = useBoolean(false)
  const [isBudgetDeleteOpen, toggleBudgetDelete, closeBudgetDelete] = useBoolean(false)

  const budgetId = useSelector(getBudgetId)
  const budgets = useBudgets()

  const style = useStyle((theme: any) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.blue,
    padding: '2rem 0',
    '.form-budget, .form-version': {
      display: 'flex',
      alignItems: 'center',
      width: 420,
      '&:not(:last-child)': {
        marginRight: '2rem',
      },
      '>*:not(:last-child)': {
        margin: 0,
        marginRight: '.6rem',
      },
    },
    '.dropdown-item': {
      color: theme.blue,
      display: 'flex',
      alignItems: 'center',
      svg: {
        marginRight: '.6rem',
      },
      '&:active': {
        backgroundColor: theme.secondary,
        color: '#fff !important',
      },
    },
  }))

  const isBudgetOk = budgets?.some((budget) => budget.Id === budgetId) || false

  return (
    <>
      <div css={style}>
        <div className="form-budget">
          <Suspense fallback={<Spinner />}>
            <BudgetSelect />

            <Button title={t('budget.createBudget')} onClick={toggleBudgetCreate}>
              <FaPlus />
            </Button>

            {isBudgetOk && (
              <Button title={t('budget.updateBudget')} onClick={toggleBudgetEdit}>
                <FaEdit />
              </Button>
            )}

            {isBudgetOk && (
              <Button onClick={toggleBudgetDelete} color="danger" title={t('budget.deleteBudget')}>
                <FaTrashAlt />
              </Button>
            )}
          </Suspense>
        </div>

        {isBudgetOk && (
          <ErrorBoundary>
            <Suspense fallback={<Spinner color="primary" />}>
              <BudgetFiltersVersions />
            </Suspense>
          </ErrorBoundary>
        )}
      </div>

      <Dialog
        isOpen={isBudgetCreateOpen}
        close={closeBudgetCreate}
        css={{ width: '80vw', maxWidth: 1200 }}
      >
        {isBudgetCreateOpen && (
          <Suspense fallback={<Loader />}>
            <BudgetCreate done={closeBudgetCreate} />
          </Suspense>
        )}
      </Dialog>

      <Dialog
        isOpen={isBudgetEditOpen}
        close={closeBudgetEdit}
        css={{ width: '80vw', maxWidth: 1200 }}
      >
        {isBudgetEditOpen && (
          <Suspense fallback={<Loader />}>
            <BudgetEdit done={closeBudgetEdit} />
          </Suspense>
        )}
      </Dialog>

      <Dialog isOpen={isBudgetDeleteOpen} close={closeBudgetDelete} css={{ width: 400 }}>
        {isBudgetDeleteOpen && <BudgetDelete done={closeBudgetDelete} />}
      </Dialog>
    </>
  )
}
