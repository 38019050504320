import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Button, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { IoMdSwap } from 'react-icons/io'
import { FaFileExcel } from 'react-icons/fa'

import range from 'lodash/range'
import upperFirst from 'lodash/upperFirst'
import flatMap from 'lodash/flatMap'
import pick from 'lodash/pick'

import { getNatures, getStartDate, getEndDate } from 'store/analyse'
import { naturesRef } from 'referentiel'
import { useStyle, useBoolean } from 'hooks'
import { noDecimalFormat } from 'helpers/formatter'
import { downloadCSV } from 'helpers/downloadFile'

const natureMap = (key: string) => {
  return key === 'Vol'
    ? 0
    : key === 'Nrj'
    ? 1
    : key === 'Tpt'
    ? 2
    : key === 'Tax'
    ? 3
    : key === 'Aut'
    ? 4
    : key === 'Tot'
    ? 5
    : -1
}

const formatDate = (date: string | null) => {
  if (date === null) return '?'
  const m = moment(date)
  return m.isValid() ? upperFirst(m.format('MMMM YYYY')) : '?'
}

const isValid = (value: any) =>
  value !== null &&
  typeof value === 'number' &&
  ![Number.MAX_VALUE, Number.MIN_VALUE, Infinity, -Infinity].includes(value)

const formatValue = (value: any) => {
  if (!isValid(value)) return '-'
  return noDecimalFormat(value)
}

type IndicatorKey =
  | 'Reel'
  | 'Budget'
  | 'ReelN1'
  | 'EcartBudget'
  | 'EcartPctBudget'
  | 'EcartN1'
  | 'EcartPctN1'
  | 'Facture'
  | 'ImpactPrixBudget'
  | 'ImpactPrixN1'
  | 'ImpactVolumeBudget'
  | 'ImpactVolumeN1'

const indicatorKeysMap: IndicatorKey[] = [
  'Reel', // 0
  'Budget', // 1
  'ReelN1', // 2
  'EcartBudget', // 3
  'EcartPctBudget', // 4
  'EcartN1', // 5
  'EcartPctN1', // 6
  'Facture', // 11
  'ImpactPrixBudget', // 7
  'ImpactPrixN1', // 8
  'ImpactVolumeBudget', // 9
  'ImpactVolumeN1', // 10
]

const reelPrevuHeader = (date: string, format?: string) => {
  const m = format ? moment(date, format) : moment(date)
  const revolu = moment().isAfter(m.endOf('month').add(10, 'days'))
  return revolu ? 'referentiel.indicateur.reel' : 'referentiel.indicateur.reelPrevu'
}

const borderStyle = '1px solid rgb(0,0,0,.1)'
const borderStyleBold = '1px solid rgb(0,0,0,0.3)'

export const MensuelTable: React.FC<{
  data: SuiviMensuel
  indicators: any[]
  filenamePrefix: string
  striped?: boolean
  hover?: boolean
}> = ({ data, indicators, filenamePrefix, striped = false, hover = false, ...props }) => {
  const { t } = useTranslation()
  const startDate = useSelector(getStartDate) as string
  const endDate = useSelector(getEndDate) as string
  const monthCount = 1 + moment(endDate).diff(moment(startDate), 'months', true)
  const showTotalColumn = monthCount > 1
  const [natureFirst, toggleNatureFirst] = useBoolean(false)

  const perimetre = (data?.LignesEntite ?? []).map(({ Nom }) => Nom)

  const selectedNatures = useSelector(getNatures)
  const natures = naturesRef(t).filter((nat) => selectedNatures.includes(nat.value))

  const indicatorKeys = indicators.map(({ value }) => indicatorKeysMap[value])

  const dates = React.useMemo(() => {
    const line1 = data?.LignesEntite[0]?.LignesMesure[0]?.Data
    if (!line1) return []
    const line = showTotalColumn ? line1 : line1.slice(0, -1)
    return line.map(({ Date }, index) =>
      index === line.length - 1 && Date === null ? 'TOTAL' : formatDate(Date),
    )
  }, [data, showTotalColumn])

  const mesures =
    data?.LignesEntite[0]?.LignesMesure.filter((_, index) => selectedNatures.includes(index)) || []

  const rowRef = React.useRef<HTMLTableRowElement>(null)
  const colRef = React.useRef<HTMLTableCellElement>(null)
  const [width, setWidth] = React.useState<number | null>(null)
  const [height, setHeight] = React.useState<number | null>(null)

  React.useEffect(() => {
    const height = rowRef.current?.getBoundingClientRect().height
    if (height) setHeight(height)

    const width = colRef.current?.getBoundingClientRect().width
    if (width) setWidth(width)
  }, [data, natureFirst])

  const css = useStyle((theme: any) => ({
    maxHeight: 500,
    maxWidth: '100%',
    overflow: 'auto',

    table: {
      backgroundColor: theme.gray100,
      margin: 0,
      borderCollapse: 'separate',
      borderSpacing: 0,
      border: 0,
      fontSize: 13,
    },

    'th, td': {
      border: 0,
      borderTop: borderStyle,
      borderLeft: borderStyle,
      lineHeight: 1.2,
      verticalAlign: 'middle',
      height: 42,
    },
    td: {
      textAlign: 'right',
      minWidth: 85,
    },

    // sticky header
    thead: {
      tr: {
        th: {
          backgroundColor: theme.gray100,
          position: 'sticky !important',
          zIndex: 2,
          height: height || 'unset',
          borderBottom: 0,
          textAlign: 'center',
        },
        '&:nth-of-type(1)': {
          th: {
            top: 0,
          },
        },
        '&:nth-of-type(2)': {
          th: {
            top: height || 'unset',
            boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important',
          },
        },
      },
    },

    // sticky aside
    '.sticky': {
      backgroundColor: theme.gray100,
      position: 'sticky !important',
    },
    '.sticky1': {
      left: 0,
      minWidth: 160,
      borderLeft: borderStyle,
    },
    '.sticky2': {
      left: width || 'unset',
      minWidth: 160,
      borderRight: borderStyle,
    },
    'tbody .sticky': {
      zIndex: 1,
    },
    'thead .sticky': {
      zIndex: 3,
      boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important',
    },

    tbody: {
      tr: {
        // stripes
        '&:nth-of-type(odd)': {
          backgroundColor: striped ? theme.gray200 : '#fff',
          '.sticky2': {
            backgroundColor: striped ? theme.gray200 : '#fff',
          },
        },
        // hover
        '&:hover': {
          ...(hover && {
            backgroundColor: theme.gray300,
            '.sticky2': {
              backgroundColor: theme.gray300,
            },
          }),
        },
      },
    },
    '.cell-ok': {
      backgroundColor: 'hsl(1225, 42%, 90%)',
    },
    '.cell-ko': {
      backgroundColor: 'hsl(355, 42%, 90%)',
    },
    [`td:nth-of-type(${indicators.length}n + 1)`]: {
      borderLeft: borderStyleBold,
    },
    [`.tr-indicators th:nth-of-type(${indicators.length}n + ${3})`]: {
      borderLeft: borderStyleBold,
    },
    '.th-date': {
      borderLeft: borderStyleBold,
    },
    [`tbody tr:nth-of-type(${mesures.length}n + 1)`]: {
      'th, td': {
        borderTop: borderStyleBold,
      },
    },
    '.th-entity': {
      borderTop: borderStyleBold,
    },
  }))

  const exportCSV = React.useCallback(() => {
    const header1 = ['', '', ...flatMap(dates, (d) => range(indicators.length).map(() => d))]

    const headers2a = [
      natureFirst ? t('suivi.nature') : t('suivi.perimetre'),
      natureFirst ? t('suivi.perimetre') : t('suivi.nature'),
    ]
    const header2 = [
      ...headers2a,
      ...flatMap(dates, (date) =>
        indicators.map(({ value, label }) =>
          value !== 0
            ? label
            : date === 'TOTAL'
            ? t(reelPrevuHeader(endDate))
            : t(reelPrevuHeader(date, 'MMMM YYYY')),
        ),
      ),
    ]

    const body = natureFirst
      ? flatMap(natures, (nature, natureIndex) =>
          perimetre.map((pee, peeIndex) => {
            const Data = data?.LignesEntite[peeIndex].LignesMesure[nature.value].Data ?? []
            const line = Data.slice(0, monthCount + (showTotalColumn ? 1 : 0))
            return [
              `${nature.label} (${nature.value === 0 ? data.UniteEnergie : data.Devise})`,
              `"=""${pee}"""`,
              ...flatMap(line, (item) => Object.values(pick(item, indicatorKeys))).map((value) =>
                formatValue(value),
              ),
            ]
          }),
        )
      : flatMap(data?.LignesEntite ?? [], ({ Nom, LignesMesure }) => {
          const mesures = LignesMesure.filter((_, index) => selectedNatures.includes(index)) || []
          return mesures.map(({ NomMesure, Data }) => {
            const headers = [
              `"=""${Nom}"""`,
              `${naturesRef(t)[natureMap(NomMesure)].label} (${
                naturesRef(t)[natureMap(NomMesure)].value === 0 ? data.UniteEnergie : data.Devise
              })`,
            ]

            const line = Data.slice(0, monthCount + (showTotalColumn ? 1 : 0))

            return [
              ...headers,
              ...flatMap(line, (item) => Object.values(pick(item, indicatorKeys))).map((value) =>
                formatValue(value),
              ),
            ]
          })
        })

    const content = [header1, header2, ...body].map((row) => row.join(';')).join('\n')
    const date = moment().format('YYYY-MM-DD')
    const filename = `${filenamePrefix} ${date}.csv`
    console.log(content)
    downloadCSV(filename, content)
  }, [
    dates,
    natureFirst,
    t,
    natures,
    data,
    filenamePrefix,
    indicators,
    endDate,
    perimetre,
    monthCount,
    showTotalColumn,
    indicatorKeys,
    selectedNatures,
  ])

  if (!data) return null

  if (data.LignesEntite.filter((le) => le.LignesMesure && le.LignesMesure.length > 0).length === 0)
    return <div>{t('analyse.empty')}</div>

  return (
    <div {...props}>
      <div className="d-flex justify-content-end my-3">
        <Button color="secondary" onClick={exportCSV}>
          <FaFileExcel css={{ marginRight: '0.4rem', marginTop: -3 }} />
          {t('global.exportCsv')}
        </Button>
      </div>

      <div className="border shadow-sm" css={css}>
        <Table bordered size="sm">
          <thead className="shadow-sm">
            <tr ref={rowRef}>
              <th className="sticky sticky1" colSpan={2}>
                <Button size="sm" color="secondary" outline onClick={toggleNatureFirst}>
                  <IoMdSwap size={18} />
                </Button>
              </th>

              {dates.map((date, index) => (
                <th key={`_${date}${index}`} colSpan={indicators.length} className="th-date">
                  {date}
                </th>
              ))}
            </tr>

            <tr className="tr-indicators">
              <th className="sticky sticky1" ref={colRef}>
                {natureFirst ? t('suivi.nature') : t('suivi.perimetre')}
              </th>

              <th className="sticky sticky2">
                {natureFirst ? t('suivi.perimetre') : t('suivi.nature')}
              </th>

              {flatMap(range(dates.length), (i) =>
                indicators.map(({ value, label }) => (
                  <th key={`${label}-${i}`}>
                    {value !== 0
                      ? label
                      : dates[i] === 'TOTAL'
                      ? t(reelPrevuHeader(endDate))
                      : t(reelPrevuHeader(dates[i], 'MMMM YYYY'))}
                  </th>
                )),
              )}
            </tr>
          </thead>

          <tbody>
            {natureFirst
              ? natures.map((nature, natureIndex) =>
                  perimetre.map((pee, peeIndex) => {
                    const Data = data.LignesEntite[peeIndex].LignesMesure[nature.value].Data
                    const line = Data.slice(0, monthCount + (showTotalColumn ? 1 : 0))
                    return (
                      <tr key={`${natureIndex}_${peeIndex}`}>
                        {peeIndex === 0 && (
                          <th className="sticky sticky1 th-entity" rowSpan={perimetre.length}>
                            {nature.label} ({nature.value === 0 ? data.UniteEnergie : data.Devise})
                          </th>
                        )}
                        <th className="sticky sticky2">{pee}</th>

                        {flatMap(line, (item) => Object.entries(pick(item, indicatorKeys))).map(
                          ([key, value], index) => {
                            let className = 'cell'
                            if (
                              typeof value === 'number' &&
                              isValid(value) &&
                              Math.round(value) !== 0 &&
                              ['EcartBudget', 'EcartPctBudget', 'EcartN1', 'EcartPctN1'].includes(
                                key,
                              )
                            ) {
                              className = value > 0 ? 'cell-ko' : 'cell-ok'
                            }

                            return (
                              <td key={index} className={className} title={value?.toString()}>
                                {formatValue(value)}
                              </td>
                            )
                          },
                        )}
                      </tr>
                    )
                  }),
                )
              : data.LignesEntite.map(({ Nom, LignesMesure }) => {
                  const mesures =
                    LignesMesure.filter((_, index) => selectedNatures.includes(index)) || []
                  return mesures.map(({ NomMesure, Data }, index) => {
                    const line = Data.slice(0, monthCount + (showTotalColumn ? 1 : 0))
                    return (
                      <tr key={`${Nom}_${NomMesure}_${index}`}>
                        {index === 0 && (
                          <th className="sticky sticky1 th-entity" rowSpan={mesures.length}>
                            {Nom}
                          </th>
                        )}

                        <th className="sticky sticky2">
                          {naturesRef(t)[natureMap(NomMesure)].label} (
                          {naturesRef(t)[natureMap(NomMesure)].value === 0
                            ? data.UniteEnergie
                            : data.Devise}
                          )
                        </th>

                        {flatMap(line, (item) => Object.entries(pick(item, indicatorKeys))).map(
                          ([key, value], index) => {
                            let className = 'cell'
                            if (
                              typeof value === 'number' &&
                              isValid(value) &&
                              Math.round(value) !== 0 &&
                              ['EcartBudget', 'EcartPctBudget', 'EcartN1', 'EcartPctN1'].includes(
                                key,
                              )
                            ) {
                              className = value > 0 ? 'cell-ko' : 'cell-ok'
                            }

                            return (
                              <td key={index} className={className} title={value?.toString()}>
                                {formatValue(value)}
                              </td>
                            )
                          },
                        )}
                      </tr>
                    )
                  })
                })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
