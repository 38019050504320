import React from 'react'
import { darken } from 'polished'

import { useStyle } from 'hooks'

export const Tab: React.FC<{
  selected?: boolean
  color?: 'light'
  disabled?: boolean
}> = ({ selected = false, color, disabled = false, children }) => {
  const style = useStyle((theme: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: color === 'light' ? theme.blue : '#fff',
    fontWeight: 400,
    opacity: disabled ? 0.4 : selected ? 1 : 1,
    position: 'relative',
    pointerEvents: disabled ? 'none' : 'auto',
    '&:after': {
      content: '""',
      display: selected ? 'block' : 'none',
      height: 5,
      backgroundColor: color === 'light' ? theme.blue : darken(0.15, theme.gold),
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },
    '&:hover': {
      opacity: 1,
    },
  }))

  return <div css={style}>{children}</div>
}

export const Tabs: React.FC<{
  index: number
  color?: 'light'
}> = ({ index, color, children, ...props }) => {
  const style = useStyle(() => ({
    height: '100%',
    display: 'flex',
    '>*:not(:last-child)': { marginRight: '2rem' },
  }))

  return (
    <nav {...props} css={style}>
      {React.Children.map(children, (child: any, i) =>
        React.cloneElement(child, { selected: index === i, color }),
      )}
    </nav>
  )
}
