import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import { CustomInput } from 'reactstrap'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

import { Section, Select, MultiSelect, DateInput } from 'components/atoms'
import { dateFormatMonths } from 'helpers/formatter'
import { CountrySelect } from './CountrySelect'

import {
  getEndDate,
  setEndDate,
  getStartDate,
  setStartDate,
  getNatures,
  setNatures,
  getEnergy,
  setEnergy,
  getAggregation,
  setAggregation,
  getIndicators,
  setIndicators,
  getEcart,
  setEcart,
  getEcartFilter,
  setEcartFilter,
  getEcartPct,
  setEcartPct,
} from 'store/analyse'

import {
  energiesRef,
  naturesRef,
  aggregationsRef,
  indicatorsRef,
  suiviIndicatorsRef,
  ecartsRef,
} from 'referentiel'

const formatDate = (date: string) =>
  moment(date, dateFormatMonths())
    .startOf('month')
    .format()

export const AnalyseFilters = () => {
  const { t } = useTranslation()
  const matchEcarts = useRouteMatch('/analyse/ecarts')
  const matchSuivi = useRouteMatch('/analyse/suivi-mensuel')
  const dispatch = useDispatch()

  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const energy = useSelector(getEnergy)
  const natures = useSelector(getNatures)
  const aggregation = useSelector(getAggregation)
  const indicators = useSelector(getIndicators)
  const ecart = useSelector(getEcart)
  const ecartFilter = useSelector(getEcartFilter)
  const ecartPct = useSelector(getEcartPct)

  const [range, setRange] = React.useState(ecartPct)

  const cb = debounce((value: number) => dispatch(setEcartPct(value)), 200)
  const debounced = React.useCallback(cb, [cb])

  return (
    <Section
      minHeight={100}
      css={{ marginBottom: '2rem', padding: '1rem 1.5rem', '.label': { width: 70 } }}
    >
      <div
        css={{
          display: 'grid',
          gridGap: '2rem',
          gridTemplateColumns: '250px 250px 1fr 250px',
          gridTemplateAreas: `
            'a b c d'
          `,
        }}
      >
        <div css={{ gridArea: 'a' }}>
          <DateInput
            value={startDate}
            onChange={(value) => {
              dispatch(setStartDate(formatDate(value)))
            }}
            view="months"
            format={dateFormatMonths()}
            label={t('analyse.debut')}
            inline
            isValidDate={(current) =>
              !!endDate && current.startOf('month').isSameOrBefore(moment(endDate).endOf('month'))
            }
          />

          <DateInput
            value={endDate}
            onChange={(value) => {
              dispatch(setEndDate(formatDate(value)))
            }}
            view="months"
            format={dateFormatMonths()}
            label={t('analyse.fin')}
            inline
            isValidDate={(current) =>
              !!startDate &&
              current.startOf('month').isSameOrAfter(moment(startDate).startOf('month'))
            }
          />
        </div>

        <div css={{ gridArea: 'b' }}>
          <Select
            options={aggregationsRef(t)}
            value={aggregation}
            onChange={(values) => {
              dispatch(setAggregation(values))
            }}
            label={t('analyse.afficher')}
            inline
          />

          <Select
            options={energiesRef(t)}
            value={energy}
            onChange={(value) => {
              dispatch(setEnergy(value))
            }}
            label={t('analyse.energie')}
            inline
          />
        </div>

        <div css={{ gridArea: 'c' }}>
          {!matchEcarts && (
            <>
              <MultiSelect
                options={naturesRef(t)}
                values={natures}
                onChange={(values) => {
                  dispatch(setNatures(values))
                }}
                label={t('analyse.nature')}
                displayMax={5}
                inline
              />

              <MultiSelect
                options={matchSuivi ? suiviIndicatorsRef(t) : indicatorsRef(t)}
                values={indicators}
                onChange={(values) => {
                  dispatch(setIndicators(values))
                }}
                displayMax={5}
                label={t('analyse.indicateurs')}
                inline
              />
            </>
          )}

          {matchEcarts && (
            <>
              <MultiSelect
                options={naturesRef(t)}
                values={natures}
                onChange={(values) => {
                  dispatch(setNatures(values))
                }}
                label={t('analyse.nature')}
                displayMax={5}
                inline
              />

              <CountrySelect />
            </>
          )}
        </div>

        <div css={{ gridArea: 'd' }}>
          {!matchEcarts && <CountrySelect />}

          {matchEcarts && (
            <>
              <Select
                options={ecartsRef(t)}
                value={ecart}
                onChange={(value) => {
                  dispatch(setEcart(value))
                }}
                label={t('analyse.ecarts')}
                inline
              />

              <div>
                <CustomInput
                  type="checkbox"
                  id="ecart-filter"
                  label={`${t('analyse.ecartsPlusDe')} ${ecartPct}%`}
                  css={{ width: 300, marginRight: '1rem' }}
                  checked={ecartFilter}
                  onChange={() => {
                    dispatch(setEcartFilter(!ecartFilter))
                  }}
                />

                <CustomInput
                  type="range"
                  id="ecart-range"
                  min={0}
                  max={100}
                  step={1}
                  value={range}
                  disabled={!ecartFilter}
                  onChange={(e) => {
                    const value = Number(e.target.value)
                    setRange(value)
                    debounced(value)
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Section>
  )
}
