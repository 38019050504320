import React from 'react'

import { isUor } from 'api'
import { EnergyIcon } from 'components/atoms'
import { Node } from './TreeSelect'

const randomId = () => Math.floor(Math.random() * 1000000)

export const createFlatTree: (node: Uor) => Node[] = (node) => {
  const tree: any[] = []

  const foo = (node: OrgaNode) => {
    if (isUor(node)) {
      node.UorEnfants?.forEach?.(foo)
      node.UorSites?.forEach?.(foo)
    } else {
      tree.push({
        id: randomId(),
        name: node.SphNom || '?',
        children: (node.SitPees ?? []).map((pee) => ({
          id: pee.id,
          name: (
            <>
              {pee.PeeNrjId && <EnergyIcon id={pee.PeeNrjId} />}
              <span>{pee.PeeClef || '?'}</span>
            </>
          ),
        })),
      })
    }
  }

  foo(node)

  return tree
}

export const createTree: (node: OrgaNode) => Node = (node) => {
  if (isUor(node)) {
    const uors =
      node.UorEnfants &&
      node.UorEnfants.map(createTree).filter((item: any) => item.children.length > 0)
    const sites =
      node.UorSites && node.UorSites.map(createTree).filter((item: any) => item.children.length > 0)

    return {
      id: randomId(),
      value: node.UorNom,
      name: <span>{node.UorNom || '?'}</span>,
      children: [...(uors || []), ...(sites || [])],
    }
  }

  const firstPee = node.SitPees?.[0] ?? null

  return {
    id: randomId(),
    value: node.SphNom,
    country: firstPee?.IdPays ?? null,
    name: (
      <>
        {firstPee && (
          <span
            title={firstPee.LibellePays}
            className={`flag-icon flag-icon-${firstPee.CodePays.toLowerCase()}`}
          />
        )}
        <span>{node.SphNom || '?'}</span>
      </>
    ),
    children: (node.SitPees ?? []).map((pee) => ({
      id: pee.id,
      value: pee.PeeClef,
      country: pee.IdPays,
      energy: pee.PeeNrjId,
      name: (
        <>
          {pee.PeeNrjId && <EnergyIcon id={pee.PeeNrjId} />}
          <span>{pee.PeeClef || '?'}</span>
        </>
      ),
    })),
  }
}
