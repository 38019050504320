import React from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { useBudgetContext } from './BudgetContext'
import { useUpdateVersion, useBudgetName, useVersionName, useVersion } from 'api'
import { addNotification } from 'notification'
import { useStyle } from 'hooks'
import { DateField } from 'components/form/DateField'
import { dateFormat } from 'helpers/formatter'

export const VersionSave: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const { chroniques } = useBudgetContext()
  const [mutate, { status }] = useUpdateVersion()
  const budgetName = useBudgetName()
  const versionName = useVersionName()
  const version = useVersion()

  const values = {
    date: !version ? null : moment(version.DateReprevision).format(dateFormat()),
  }

  const schema = () =>
    Yup.object().shape({
      date: Yup.string().required(t('global.requiredField')),
    })

  const handleSubmit = React.useCallback(
    async (values, actions) => {
      if (!version) return null
      try {
        await mutate({
          version: {
            ...version,
            DateReprevision: values.date,
            Chroniques: chroniques,
          },
        })
        setTimeout(done)
        addNotification({
          type: 'success',
          title: `${t('budget.budget')} ${budgetName}`,
          message: t('saveVersion.successMessage', { date: versionName?.date }),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('saveVersion.errorTitle'),
          message: (error as any).toString(),
        })
      } finally {
        actions.setSubmitting(false)
      }
    },
    [budgetName, chroniques, done, mutate, t, version, versionName],
  )

  const style = useStyle(() => ({
    header: {
      marginBottom: '2rem',
    },
    '.fields': {
      width: 200,
    },
    footer: {
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: 0,
      bottom: 0,
      margin: '2rem',
    },
  }))

  if (!versionName) return null

  const { numero, date } = versionName

  return (
    <div css={style}>
      <header>
        <h5>{t('saveVersion.title', { numero, date })}</h5>
      </header>

      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <div className="fields">
              <DateField name="date" label="Date" />
            </div>

            <footer>
              <Button disabled={status === 'loading' || props.isSubmitting} type="submit">
                {status === 'loading' && <Spinner size="sm" className="mr-2" />}{' '}
                {t('saveVersion.validate')}
              </Button>
            </footer>
          </Form>
        )}
      </Formik>
    </div>
  )
}
