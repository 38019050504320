import React, { Suspense } from 'react'
import { Spinner } from 'reactstrap'

import { Version } from 'commons'
import { useStyle } from 'hooks'
import { ErrorBoundary } from 'components/atoms'

export const PageLayout: React.FC<{ Menu?: React.ReactChild }> = ({
  Menu = null,
  children,
  ...props
}) => {
  const style = useStyle((theme: any) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '.header': {
      backgroundColor: theme.gray100,
      padding: `0 2rem`,
      display: 'flex',
      alignItems: 'center',
      zIndex: 10,
    },
    '.scrollzone': {
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      main: {
        flex: 1,
        padding: '2rem',
      },
    },
    '.footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '.4rem .6rem',
      fontSize: '.8rem',
      color: theme.gray600,
    },
  }))

  return (
    <div css={style} {...props}>
      {Menu && (
        <header className="shadow-sm header">
          <ErrorBoundary>
            <Suspense fallback={<Spinner color="primary" style={{ margin: `2rem 0` }} />}>
              {Menu}
            </Suspense>
          </ErrorBoundary>
        </header>
      )}

      <div className="scrollzone">
        <main>{children}</main>

        <footer className="footer">
          <Version />
        </footer>
      </div>
    </div>
  )
}
