import React from 'react'
import ReactDOM from 'react-dom'

import 'typeface-muli'
import 'styles/index.scss'

import 'i18n/moment'
import App from 'App'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root') as HTMLElement

ReactDOM.render(<App />, rootElement)

// Strict mode
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   rootElement,
// )

// Concurrent mode
// ReactDOM.createRoot(rootElement).render(<App />)

serviceWorker.register()
