import React, { FC, Suspense } from 'react'

import { useStyle } from 'hooks'
import { ErrorBoundary, Loader } from 'components/atoms'

interface Props {
  className?: string
  Icon?: FC
  title?: string
  minHeight?: number
}

export const Section: FC<Props> = ({
  title,
  Icon,
  minHeight = 100,
  className,
  children,
  ...props
}) => {
  const style = useStyle((theme: any) => ({
    background: theme.gray100,
    padding: '1.5rem',
    border: `1px solid ${theme.gray400}`,
    '>h6': {
      marginBottom: '2rem',
      display: 'flex',
      alignItems: 'flex-start',
      '>*:not(:last-child)': { marginRight: '.6rem' },
    },
    '>div': {
      position: 'relative',
      height: 'calc(100% - 45px)',
      minHeight,
    },
  }))

  return (
    <section css={style} className={`rounded-sm shadow-sm ${className}`} {...props}>
      {title && (
        <h6>
          {Icon && <Icon />}
          <div>{title}</div>
        </h6>
      )}
      <div>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </ErrorBoundary>
      </div>
    </section>
  )
}
