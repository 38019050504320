/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'
import matchSorter from 'match-sorter'
import { motion } from 'framer-motion'
import { FormGroup, FormFeedback, Input, Label, Badge } from 'reactstrap'
import { useRect } from '@reach/rect'

import caret from 'images/caret.svg'
// import search from 'images/search.svg'

interface Item {
  value: number
  label: string
  icon?: React.ReactChild
  disabled?: boolean
}

const getItems = (items: Item[], filter: string | null) =>
  filter
    ? matchSorter(items, filter, {
        keys: ['label'],
      })
    : items

const itemToString = (item: Item | null) => (item ? item.label : '')

const stateReducer = (state: DownshiftState<Item>, changes: StateChangeOptions<Item>) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true,
        inputValue: '',
      }
    default:
      return changes
  }
}

export const Select: React.FC<{
  options: Item[]
  value: number | null
  onChange: (value: number) => void
  placeholder?: string
  inputProps?: React.ComponentProps<typeof Input>
  label?: string
  inline?: boolean
  error?: string | null
  clearable?: boolean
  disabled?: boolean
  closeOnSelect?: boolean
  className?: string
}> = ({
  options: items,
  value,
  onChange,
  placeholder,
  inputProps,
  label,
  inline = false,
  error,
  clearable = false,
  disabled = false,
  closeOnSelect = false,
  ...extraProps
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const selRef = React.useRef<HTMLDivElement>(null)
  const rect = useRect(selRef)

  const selectedItem = items.find((item) => item.value === value) || null

  const Comp = (
    <Downshift<Item>
      stateReducer={stateReducer}
      onChange={(item: Item | null) => {
        if (item) onChange(item.value)
      }}
      selectedItem={null}
      itemToString={itemToString}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        toggleMenu,
        closeMenu,
        inputValue,
      }) => (
        <div style={{ position: 'relative', width: '100%' }} {...(!label && extraProps)}>
          <Input
            {...getInputProps()}
            innerRef={inputRef}
            placeholder={placeholder || ' '}
            onClick={() => {
              toggleMenu()
              if (!isOpen) inputRef?.current?.focus()
            }}
            invalid={!!error}
            disabled={disabled}
            {...inputProps}
            css={{
              paddingLeft: (rect?.width || 0) + 5,
              paddingRight: `calc(1.5rem + 16px)`,
              background: `#fff url(${caret}) no-repeat right 0.75rem center`,
              backgroundSize: '1.1em',
              // ...(!placeholder && {
              //   '::placeholder': {
              //     background: `url(${search}) no-repeat left 0.25rem center`,
              //     backgroundSize: '0.9em',
              //   },
              // }),
              cursor: 'pointer',
            }}
          />

          <div
            ref={selRef}
            className="selection"
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              maxWidth: 'calc(100% - 60px)',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 5,
              fontSize: 16,
              '>*': {
                maxWidth: 150,
                cursor: 'pointer',
                userSelect: 'none',
                textAlign: 'left',
                position: 'relative',
                ...(clearable && {
                  '&:hover': {
                    ':before': {
                      content: '"\u00d7 "',
                    },
                  },
                }),
                '&:not(:last-child)': {
                  marginRight: 5,
                },
              },
              '.badge': {
                backgroundColor: '#ced4da99',
              },
            }}
          >
            {selectedItem && (
              <Badge color="light" className="text-nowrap text-truncate" title={selectedItem.label}>
                {selectedItem.icon || selectedItem.label}
              </Badge>
            )}
          </div>

          <motion.div
            className="shadow"
            css={{
              maxHeight: 200,
              width: '100%',
              overflowY: 'auto',
              position: 'absolute',
              zIndex: 100,
              ul: {
                margin: 0,
                padding: 0,
                listStyleType: 'none',
              },
            }}
            animate={isOpen ? 'open' : 'close'}
            initial={false}
            variants={{
              open: {
                height: 'auto',
                display: 'block',
              },
              close: {
                height: 0,
                transitionEnd: {
                  display: 'none',
                },
              },
            }}
            transition={{
              duration: 0.2,
              ease: 'easeOut',
            }}
          >
            <ul
              {...getMenuProps()}
              onClick={() => {
                if (closeOnSelect) closeMenu()
              }}
            >
              {getItems(items, inputValue).map((item, index) => {
                const isSelected = value === item.value
                return (
                  <li
                    key={item.value}
                    {...(!item.disabled &&
                      getItemProps({
                        index,
                        item,
                      }))}
                    css={(theme: any) => ({
                      padding: '4px 8px 4px 8px',
                      cursor: 'pointer',
                      '&:not(:last-child)': {
                        borderBottom: '1px solid #eee',
                      },
                      backgroundColor: highlightedIndex === index ? theme.gray200 : '#fff',
                      fontWeight: isSelected ? 'bold' : 'normal',
                      label: {
                        marginLeft: 4,
                      },
                    })}
                  >
                    <div className="custom-control custom-radio" style={{ pointerEvents: 'none' }}>
                      <input
                        type="radio"
                        className="custom-control-input"
                        id={`${item.value}${index}`}
                        checked={isSelected}
                        onChange={() => {}}
                        disabled={item.disabled}
                      />
                      <label className="custom-control-label" htmlFor={`${item.value}${index}`}>
                        {item.icon}
                        {item.label}
                      </label>
                    </div>
                  </li>
                )
              })}
            </ul>
          </motion.div>
        </div>
      )}
    </Downshift>
  )

  return (
    <FormGroup css={{ marginBottom: '.7rem' }} {...extraProps}>
      {!label ? (
        Comp
      ) : (
        <Label
          css={
            inline
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 0,
                  '>span': {
                    flexShrink: 0,
                    margin: 0,
                    marginRight: '.8rem',
                  },
                }
              : {
                  marginBottom: '.7rem',
                  '>span': {
                    display: 'inline-block',
                    marginBottom: '.5rem',
                  },
                }
          }
        >
          <span className="label">{label}</span>
          {Comp}
        </Label>
      )}
      {error && <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>}
    </FormGroup>
  )
}
