import React from 'react'
import { motion } from 'framer-motion'
import { FaChevronDown } from 'react-icons/fa'

import { useStyle } from 'hooks'

export const BudgetTableToggle: React.FC<{
  tabIndex: number
  toggle: () => void
  isOpen: boolean
  label: React.ReactText
  Icon: React.FC<any>
}> = ({ tabIndex, toggle, isOpen, label, Icon }) => {
  const style = useStyle(() => ({
    lineHeight: 1.6,
    marginBottom: '1rem',
    userSelect: 'none',
    '.toggle-button': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '&:focus': {
        outline: 'none',
      },
    },
    '.toggle-indicator': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        verticalAlign: 'sub',
      },
    },
    '.toggle-icon': {
      verticalAlign: 'sub',
      margin: '0 0.6rem',
      height: '1em',
      width: '1em',
      top: -1,
      position: 'relative',
    },
  }))

  return (
    <h5 css={style}>
      <div
        className="toggle-button"
        role="button"
        tabIndex={tabIndex}
        onClick={toggle}
        onKeyPress={toggle}
      >
        <motion.div
          className="toggle-indicator"
          initial="close"
          animate={isOpen ? 'open' : 'close'}
          variants={{
            open: {
              rotate: 0.1,
            },
            close: {
              rotate: -90,
            },
          }}
        >
          <FaChevronDown />
        </motion.div>

        <Icon className="toggle-icon" />

        <span>{label}</span>
      </div>
    </h5>
  )
}
