import React, { FC } from 'react'
import { Button } from 'reactstrap'
import { motion } from 'framer-motion'

import { useStyle } from 'hooks'
import { Overlay } from 'components/atoms'

interface Props {
  isOpen: boolean
  close: () => void
  width?: number
  position?: 'left' | 'right'
  className?: string
}

export const SlidePanel: FC<Props> = ({
  isOpen,
  close,
  width = 400,
  position = 'left',
  children,
  ...props
}) => {
  const style = useStyle((theme: any) => ({
    position: 'absolute',
    top: 0,
    backgroundColor: theme.gray200,
    height: '100%',
    overflowY: 'auto',
    width: width,
    // padding: '1rem',
    left: position === 'right' ? '100%' : 'unset',
    right: position === 'right' ? 'unset' : '100%',
    '.close': {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      zIndex: 100,
    },
  }))

  return (
    <Overlay isOpen={isOpen} onClick={close} {...props}>
      <motion.div
        css={style}
        animate={isOpen ? 'open' : 'close'}
        variants={{
          open: {
            translateX: width * (position === 'right' ? -1 : 1),
            display: 'block',
          },
          close: {
            translateX: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        transition={{
          duration: 0.4,
          ease: 'anticipate',
        }}
      >
        <Button close onClick={close} />
        {children}
      </motion.div>
    </Overlay>
  )
}
