import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import sumBy from 'lodash/sumBy'
import sum from 'lodash/sum'

const sumByChrType = (chroniques: Chronique[], chrType: number) =>
  sum(chroniques.filter((c) => c.ChrType === chrType).map((c) => sumBy(c.Data, 'v')))

interface State {
  synthese: {
    peeCount: number
    totalVolume: number
    energyExpense: number
    taxesExpense: number
    gridExpense: number
    miscExpense: number
    totalExpense: number
  }
  chroniques: any[]
  setChroniques: any
  setValue: (peeId: number, chrType: number, monthIndex: number, value: number) => void
  setValues: (operations: any[]) => void
}

const BudgetContext = React.createContext<State | undefined>(undefined)

const BudgetProvider = ({ children }: { children: React.ReactNode }) => {
  const [chroniques, setChroniques] = React.useState<any[]>([])

  const setValue = React.useCallback(
    (peeId, chrType, monthIndex, value) => {
      const newChroniques = cloneDeep(chroniques)

      const index = chroniques.findIndex((ch: any) => peeId === ch.PeeId && chrType === ch.ChrType)
      if (index === -1) return
      newChroniques[index].Data[monthIndex].v = value

      setChroniques(newChroniques)
    },
    [chroniques],
  )

  const setValues = React.useCallback(
    (operations: any[]) => {
      const newChroniques = cloneDeep(chroniques)

      operations.forEach(({ peeId, chrType, monthIndex, value }) => {
        const index = chroniques.findIndex(
          (ch: any) => peeId === ch.PeeId && chrType === ch.ChrType,
        )
        if (index >= 0) newChroniques[index].Data[monthIndex].v = value
      })

      setChroniques(newChroniques)
    },
    [chroniques],
  )

  const synthese = React.useMemo(() => {
    const peeCount = uniqBy(chroniques, 'PeeId').length
    const totalVolume = sumByChrType(chroniques, 3000)
    const energyExpense = sumByChrType(chroniques, 3001)
    const taxesExpense = sumByChrType(chroniques, 3002)
    const gridExpense = sumByChrType(chroniques, 3003)
    const miscExpense = sumByChrType(chroniques, 3004)
    const totalExpense = energyExpense + taxesExpense + gridExpense + miscExpense
    return {
      peeCount,
      totalVolume,
      energyExpense,
      taxesExpense,
      gridExpense,
      miscExpense,
      totalExpense,
    }
  }, [chroniques])

  const state = { synthese, chroniques, setChroniques, setValue, setValues }
  return <BudgetContext.Provider value={state}>{children}</BudgetContext.Provider>
}

const useBudgetContext = () => {
  const context = React.useContext(BudgetContext)
  if (context === undefined) {
    throw new Error('useBudgetContext must be used within a BudgetProvider')
  }
  return context
}

export { BudgetProvider, useBudgetContext }
