import React, { Suspense } from 'react'
import ReactNotification from 'react-notifications-component'
import { useSelector, useDispatch } from 'react-redux'

import { getPerimetre, setPerimetre } from 'store/analyse'
import { useStyle } from 'hooks'
import { UserPanel } from 'commons'
import { useBoolean } from 'hooks'
import { AppMenu } from 'components/templates/AppMenu'
import { Sidebar } from 'components/templates/Sidebar'
import { SlidePanel, ErrorBoundary, Loader } from 'components/atoms'
import { Organisation } from 'components/sections/Perimetre/Organisation'

export const AppLayout: React.FC = (props) => {
  const [isUserPanelOpen, toggleUserPanel, closeUserPanel] = useBoolean(false)
  const [isSearchPanelOpen, toggleSearchPanel, closeSearchPanel] = useBoolean(false)

  const dispatch = useDispatch()
  const values = useSelector(getPerimetre)
  const onChange = React.useCallback((p) => dispatch(setPerimetre(p)), [dispatch])

  const style = useStyle((theme: any) => ({
    height: '100%',
    overflowX: 'hidden',
    backgroundColor: theme.gray200,
    display: 'grid',
    gridTemplateColumns: `240px calc(100% - 240px)`,
    gridTemplateRows: '70px calc(100% - 70px)',
    gridTemplateAreas: `
      'aside header'
      'aside main'
    `,
    '>header': {
      gridArea: 'header',
      backgroundColor: theme.gold,
      zIndex: 20,
    },
    '>aside': {
      gridArea: 'aside',
      backgroundColor: '#009ba4',
    },
  }))

  return (
    <div css={style}>
      <header className="shadow">
        <AppMenu toggleUserPanel={toggleUserPanel} />
      </header>

      <aside className="shadow">
        <Sidebar {...{ toggleSearchPanel, isSearchPanelOpen }} />
      </aside>

      <div css={{ gridArea: 'main' }}>
        {props.children}

        <SlidePanel
          isOpen={isSearchPanelOpen}
          close={closeSearchPanel}
          width={480}
          css={{
            left: 240,
            width: `calc(100% - 240px)`,
          }}
        >
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Organisation {...{ values, onChange }} />
            </Suspense>
          </ErrorBoundary>
        </SlidePanel>

        <SlidePanel isOpen={isUserPanelOpen} close={closeUserPanel} width={480} position="right">
          <UserPanel done={closeUserPanel} />
        </SlidePanel>
      </div>
      <ReactNotification />
    </div>
  )
}
