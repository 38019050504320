import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'

import { ErrorBoundary, Loader } from 'components/atoms'
import { EcartsTable } from 'components/sections/Analyse/EcartsTable'
import { EcartsMensuels } from 'components/sections/Analyse/EcartsMensuels'
import { ScatterPlots } from 'components/sections/Analyse/ScatterPlots'
import { getPerimetre } from 'store/analyse'

const LoadingSection = () => (
  <div css={{ position: 'relative', minHeight: 250, width: '100%' }}>
    <Loader />
  </div>
)

export const AnalysePageEcarts: React.FC = () => {
  const perimetre = useSelector(getPerimetre)
  const [hovered, setHovered] = React.useState<string | null>(null)
  const [selected, setSelected] = React.useState<number | null>(null)

  if (!perimetre || perimetre.length === 0) return null

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSection />}>
        <div
          css={{
            display: 'grid',
            gridGap: '1rem',
            gridTemplateColumns: '510px 1fr',
            gridTemplateAreas: `
                'plots table1'
                'table2 table2'
              `,
          }}
        >
          <ScatterPlots {...{ hovered, setHovered }} css={{ gridArea: 'plots' }} />

          <EcartsTable
            {...{ selected, setSelected, hovered, setHovered }}
            css={{ gridArea: 'table1' }}
          />

          <div css={{ gridArea: 'table2' }}>
            {selected && (
              <ErrorBoundary>
                <Suspense fallback={<LoadingSection />}>
                  <EcartsMensuels peeId={selected} />
                </Suspense>
              </ErrorBoundary>
            )}
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  )
}
