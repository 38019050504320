import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'

import { getBudgetId, getVersionId } from 'store/budget'
import { BudgetTables } from 'components/sections/Budget/BudgetTables'
import { ErrorBoundary, Loader } from 'components/atoms'

export const BudgetPage: React.FC = () => {
  const budgetId = useSelector(getBudgetId)
  const versionId = useSelector(getVersionId)

  if (!budgetId || !versionId) return null

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <BudgetTables />
      </Suspense>
    </ErrorBoundary>
  )
}
