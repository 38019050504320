import React from 'react'
import { FaChartLine, FaDollarSign } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getTabIndex } from 'store/analyse'
import { getBudgetId, getVersionId } from 'store/budget'
import { RouterTabs } from 'components/atoms'
import { useStyle } from 'hooks'
import { paths } from './AnalyseTabs'

export const AppTabs: React.FC = () => {
  const { t } = useTranslation()
  const tabIndex = useSelector(getTabIndex)
  const budgetId = useSelector(getBudgetId)
  const versionId = useSelector(getVersionId)

  const budgetPath =
    budgetId === null
      ? '/budget'
      : versionId === null
      ? `/budget/${budgetId}`
      : `/budget/${budgetId}/version/${versionId}`

  const labelStyle = useStyle(() => ({
    display: 'flex',
    alignItems: 'center',
    svg: { marginRight: '0.6rem' },
  }))

  const links = React.useMemo(
    () => [
      {
        path: paths[tabIndex].path,
        label: (
          <span css={labelStyle}>
            <FaChartLine />
            {t('nav.analyse')}
          </span>
        ),
        matchPath: `/analyse`,
      },
      {
        path: budgetPath,
        label: (
          <span css={labelStyle}>
            <FaDollarSign />
            {t('nav.budgets')}
          </span>
        ),
        matchPath: `/budget`,
      },
    ],
    [budgetPath, labelStyle, t, tabIndex],
  )

  return <RouterTabs links={links} />
}
