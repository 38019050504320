import React, { Suspense } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { lighten } from 'polished'
import { useSelector, useDispatch } from 'react-redux'

import { useStyle } from 'hooks'
import { ReactComponent as Logo } from 'images/logo-eleneo.svg'
import { Perimetre } from 'components/sections/Perimetre/Perimetre'
import { ErrorBoundary, Loader } from 'components/atoms'
import { getPerimetre, setPerimetre } from 'store/analyse'

export const Sidebar: React.FC<{
  toggleSearchPanel: () => void
  isSearchPanelOpen: boolean
}> = ({ toggleSearchPanel, isSearchPanelOpen }) => {
  const matchAnalyse = useRouteMatch('/analyse')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const perimetre = useSelector(getPerimetre)

  const style = useStyle((theme: any) => ({
    height: '100%',
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    color: '#fff',
    header: {
      marginBottom: '3rem',
      fontFamily: 'Merlo',
      fontWeight: 300,
      color: theme.gray200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      span: {
        fontSize: 10,
      },
      a: {
        color: theme.gray200,
        '&:hover': {
          textDecoration: 'none',
          color: '#fff',
        },
        svg: {
          color: theme.gray200,
          fill: theme.gray600,
          width: 'calc(1.2rem * 3.6)',
          height: '1.2rem',
          marginLeft: '.5rem',
          marginRight: '.5rem',
          marginBottom: 3,
          verticalAlign: 'bottom',
        },
      },
    },
    '.perimetre': {
      flex: 1,
      width: '100%',
      position: 'relative',
    },
  }))

  return (
    <nav css={style}>
      <header>
        <Link to="/" css={{ fontSize: '3.6rem' }}>
          eBudget
        </Link>

        <span>
          by
          <a href="https://www.eleneo.fr">
            <Logo />
          </a>
        </span>
      </header>

      {matchAnalyse && (
        <>
          <Button
            onClick={toggleSearchPanel}
            css={{
              width: '80%',
              marginBottom: '3rem',
              backgroundColor: '#98d2dd',
              color: '#ffffff !important',
              border: '#98d2dd',
            }}
          >
            {isSearchPanelOpen ? t('sidebar.closePanel') : t('sidebar.openPanel')}
          </Button>

          <div className="perimetre">
            <ErrorBoundary>
              <Suspense fallback={<Loader />}>
                <Perimetre />
              </Suspense>
            </ErrorBoundary>
          </div>

          {perimetre !== null && perimetre.length > 0 && (
            <Button
              onClick={() => {
                dispatch(setPerimetre([]))
              }}
              color="link"
              css={(theme: any) => ({
                margin: '1rem auto',
                '&:hover, &:focus': {
                  textDecoration: 'none',
                  color: lighten(0.1, theme.gold),
                },
              })}
            >
              {t('sidebar.emptySelection')}
            </Button>
          )}
        </>
      )}
    </nav>
  )
}
