import React, { FC } from 'react'

import { ReactComponent as Gas } from 'images/gas.svg'
import { ReactComponent as Elec } from 'images/elec.svg'

const style = {
  width: '.9rem',
  height: '.9rem',
  marginRight: '0.4rem',
  verticalAlign: 'baseline',
}

export const EnergyIcon: FC<{ id: number }> = ({ id }) => {
  if (id === 1) return <Gas style={{ ...style, color: '#c53ed1' }} />
  if (id === 2) return <Elec style={{ ...style, color: '#31acea' }} />
  return null
}
