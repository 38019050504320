import React from 'react'
import { FaChartLine } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useStyle } from 'hooks'
import { PageTabs } from 'components/templates/PageTabs'
import { setTabIndex } from 'store/analyse'

export const paths = [
  { path: '/analyse/suivi-mensuel', label: 'nav.suivi', Icon: <FaChartLine /> },
  { path: '/analyse/atterrissage', label: 'nav.atterrissage', Icon: <FaChartLine /> },
  { path: '/analyse/ecarts', label: 'nav.ecarts', Icon: <FaChartLine /> },
]

export const AnalyseTabs = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const style = useStyle(() => ({
    display: 'flex',
    alignItems: 'center',
    svg: { marginRight: '0.6rem' },
  }))

  const links = React.useMemo(
    () =>
      paths.map(({ path, label, Icon }, index) => ({
        path,
        label: (
          <span css={style}>
            {Icon}
            {t(label)}
          </span>
        ),
        onClick: () => dispatch(setTabIndex(index)),
      })),
    [dispatch, style, t],
  )
  return <PageTabs links={links} />
}
