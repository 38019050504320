import React from 'react'

import { useStyle } from 'hooks'
import { RouterTabs } from 'components/atoms'

export const PageTabs: React.FC<{
  links: {
    label: string | React.ReactElement
    path: string
    matchPath?: string
    disabled?: boolean
  }[]
}> = ({ links }) => {
  const style = useStyle((theme: any) => ({
    background: theme.gray100,
    height: 65,
    padding: `5px 0 0`,
  }))

  return (
    <div css={style}>
      <RouterTabs color="light" links={links} />
    </div>
  )
}
