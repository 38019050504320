import React from 'react'
import { Table } from 'reactstrap'
import { FaTimes } from 'react-icons/fa'
import range from 'lodash/range'
import { useTranslation } from 'react-i18next'

import { usePees } from 'api'
import { useStyle } from 'hooks'
import { EnergyIcon } from 'components/atoms'

export const BudgetPerimetreTable: React.FC<any> = ({ values, onChange }) => {
  const { t } = useTranslation()
  const pees = usePees()

  const css = useStyle(() => ({
    maxHeight: '63vh',
    width: '100%',
    overflow: 'auto',
    paddingLeft: '1rem',
    td: {
      maxWidth: 180,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '>span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    table: {
      background: '#fff',
      margin: 0,
    },
  }))

  const diff = 10 - values.length
  const restCount = diff < 0 ? 0 : diff

  return (
    <div css={css}>
      <Table size="sm" striped hover bordered>
        <thead>
          <tr>
            <th></th>
            <th>{t('budget.perimetreTable.bu')}</th>
            <th>{t('budget.perimetreTable.pays')}</th>
            <th>{t('budget.perimetreTable.site')}</th>
            <th>{t('budget.perimetreTable.pee')}</th>
          </tr>
        </thead>
        <tbody>
          {values.map((id: number) => {
            const pee = pees[id]
            if (!pee) return null
            return (
              <tr key={pee.id}>
                <td>
                  <FaTimes
                    css={{ color: '#666', cursor: 'pointer' }}
                    onClick={() => onChange(values.filter((x: number) => x !== pee.id))}
                  />
                </td>
                <td title={pee.UorNom}>{pee.UorNom}</td>
                <td title={pee.LibellePays}>
                  <span className={`flag-icon flag-icon-${pee.CodePays.toLowerCase()}`} />
                </td>
                <td title={pee.SphNom}>{pee.SphNom}</td>
                <td title={pee.PeeClef}>
                  <span>
                    <EnergyIcon id={pee.PeeNrjId} />
                    <span>{pee.PeeClef}</span>
                  </span>
                </td>
              </tr>
            )
          })}
          {range(restCount).map((i) => (
            <tr key={i}>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
