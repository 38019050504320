import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import { useTranslation } from 'react-i18next'

import { getBudgetId, getVersionId } from 'store/budget'
import { useVersions } from 'api'
import { Select } from 'components/atoms'
import { dateFormat } from 'helpers/formatter'

export const VersionSelect: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const budgetId = useSelector(getBudgetId)
  const versionId = useSelector(getVersionId)
  const versions = useVersions()

  const options = React.useMemo(() => {
    if (!versions) return []
    return reverse(sortBy(versions, ['Numero'])).map(({ Numero, DateReprevision, Id }) => ({
      value: Id,
      label: `#${Numero} - ${moment(DateReprevision).format(dateFormat())}`,
    }))
  }, [versions])

  React.useEffect(() => {
    if (!versionId && !!versions && versions.length > 0) {
      const lastVersion = versions.reduce(
        (acc, v) => (moment(v.DateReprevision).isAfter(moment(acc.DateReprevision)) ? v : acc),
        versions[0],
      )
      history.push(`/budget/${budgetId}/version/${lastVersion.Id}`)
    }
  }, [budgetId, history, versionId, versions])

  const invalid = versionId !== null && !versions?.some((version) => version.Id === versionId)
  if (invalid) return null

  return (
    <Select
      value={invalid ? null : versionId}
      onChange={(value) => {
        history.push(`/budget/${budgetId}/version/${value}`)
      }}
      options={options}
      label={t('budget.version')}
      error={invalid ? `${t('budget.version')} ${t('budget.notFound')}` : null}
      inline
    />
  )
}
