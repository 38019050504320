import React from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { useBudgetContext } from './BudgetContext'
import { useCreateVersion, useBudgetName, useVersionName, useVersion } from 'api'
import { addNotification } from 'notification'
import { useStyle } from 'hooks'
import { DateField } from 'components/form/DateField'
import { getBudgetId } from 'store/budget'
import { useTranslation } from 'react-i18next'
import { dateFormat } from 'helpers/formatter'

export const VersionSaveNew: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const { chroniques } = useBudgetContext()
  const [mutate, { data: createdVersion, status }] = useCreateVersion()
  const budgetName = useBudgetName()
  const versionName = useVersionName()
  const version = useVersion()
  const history = useHistory()
  const budgetId = useSelector(getBudgetId)

  const schema = () =>
    Yup.object().shape({
      date: Yup.string().required(t('global.requiredField')),
    })

  React.useEffect(() => {
    if (!createdVersion) return
    setTimeout(() => {
      done()
      history.push(`/budget/${budgetId}/version/${createdVersion.Id}`)
    })
    addNotification({
      type: 'success',
      title: `${t('budget.budget')} ${budgetName}`,
      message: t('saveNewVersion.successMessage', {
        name: moment(createdVersion.DateReprevision).format(dateFormat()),
      }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdVersion])

  const style = useStyle(() => ({
    header: {
      marginBottom: '2rem',
    },
    '.fields': {
      width: 200,
    },
    footer: {
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: 0,
      bottom: 0,
      margin: '2rem',
    },
  }))

  const values = {
    date: !version ? null : moment(version.DateReprevision).format(dateFormat()),
  }

  const handleSubmit = React.useCallback(
    async (values, actions) => {
      try {
        actions.setSubmitting(true)
        if (status === 'loading') return

        await mutate({
          date: values.date,
          chroniques,
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('saveVersion.errorTitle'),
          message: (error as any).toString(),
        })
      } finally {
        actions.setSubmitting(false)
      }
    },
    [chroniques, mutate, status, t],
  )

  if (!versionName) return null

  return (
    <div css={style}>
      <header>
        <h5>{t('saveNewVersion.title')}</h5>
      </header>

      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <div className="fields">
              <DateField name="date" label="Date" />
            </div>

            <footer>
              <Button disabled={status === 'loading' || props.isSubmitting} type="submit">
                {status === 'loading' && <Spinner size="sm" className="mr-2" />}{' '}
                {t('saveVersion.validate')}
              </Button>
            </footer>
          </Form>
        )}
      </Formik>
    </div>
  )
}
