import React from 'react'
import DateTime from 'react-datetime'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import moment, { Moment } from 'moment'

import calendar from 'images/calendar.svg'
import { dateFormat } from 'helpers/formatter'

export const DateInput: React.FC<{
  value: string | null
  onChange: (value: string) => void
  view: 'days' | 'months' | 'years'
  format: string
  inputProps?: React.ComponentProps<typeof Input>
  label?: string
  inline?: boolean
  isValidDate?: (current: Moment) => boolean
}> = ({
  value,
  onChange,
  view = 'days',
  inputProps,
  format = dateFormat(),
  label,
  inline = false,
  isValidDate = () => true,
  ...extraProps
}) => {
  const [inputValue, setInputValue] = React.useState(
    value === null ? null : moment(value).format(format),
  )
  const [invalid, setInvalid] = React.useState(false)

  React.useEffect(() => {
    setInputValue(value === null ? null : moment(value).format(format))
  }, [format, value])

  const Comp = (
    <DateTime
      value={value}
      onChange={(date: any) => {
        onChange(date.format(format))
        setInputValue(date.format(format))
        setInvalid(false)
      }}
      initialViewMode={view}
      updateOnView={view}
      dateFormat={format}
      timeFormat={false}
      utc={true}
      closeOnSelect={true}
      isValidDate={isValidDate}
      renderInput={({ value, onChange, ...props }: any) => (
        <div {...(!label && extraProps)}>
          <Input
            {...props}
            placeholder={format}
            value={inputValue || ''}
            onChange={(e) => {
              if (moment(e.target.value, format, true).isValid()) {
                onChange(e)
                setInvalid(false)
              } else {
                setInputValue(e.target.value)
                setInvalid(true)
              }
            }}
            css={{
              paddingRight: `calc(1.5rem + 16px)`,
              background: `#fff url(${calendar}) no-repeat right 0.75rem center`,
              backgroundSize: '1.1em',
              cursor: 'default',
            }}
            {...inputProps}
            invalid={inputProps?.invalid || invalid}
          />
          {invalid && <FormFeedback>Format {format}</FormFeedback>}
        </div>
      )}
    />
  )

  return (
    <FormGroup
      css={
        inline
          ? {
              display: 'flex',
              alignItems: 'center',
              marginBottom: '.7rem',
              label: {
                flexShrink: 0,
                margin: 0,
                marginRight: '.6rem',
              },
            }
          : {
              marginBottom: '.7rem',
              label: {
                display: 'inline-block',
                marginBottom: '.5rem',
              },
            }
      }
      {...extraProps}
    >
      {!label ? (
        Comp
      ) : (
        <>
          <Label className="label">{label}</Label>
          {Comp}
        </>
      )}
    </FormGroup>
  )
}
