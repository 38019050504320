import React from 'react'
import { MultiSelect } from 'components/atoms'
import has from 'lodash/has'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import { useTranslation } from 'react-i18next'

import { useOrganisation } from 'api'
import { energiesRef } from 'referentiel'

export const EnergySelect: React.FC<{
  values: number[]
  onChange: (values: number[]) => void
}> = ({ values, onChange }) => {
  const { t } = useTranslation()
  const orga = useOrganisation()

  const available = React.useMemo(() => {
    const getEnergies = (node: any) => {
      if (has(node, 'PeeNrjId')) return node.PeeNrjId
      const countries = []
      if (has(node, 'SitPees')) countries.push(...node.SitPees.map(getEnergies))
      if (has(node, 'UorEnfants')) countries.push(...node.UorEnfants.map(getEnergies))
      if (has(node, 'UorSites')) countries.push(...node.UorSites.map(getEnergies))
      return uniq(flatten(countries))
    }

    return getEnergies(orga)
  }, [orga])

  const options = energiesRef(t).map((energy) => ({
    ...energy,
    disabled: !available.includes(energy.value),
  }))

  return (
    <MultiSelect
      options={options}
      values={values}
      onChange={onChange}
      label={t('global.energy')}
      inline
      clearable
    />
  )
}
