/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react'
import { Button, Table } from 'reactstrap'
import { useSelector } from 'react-redux'
import { transparentize } from 'polished'
import { FaFileExcel } from 'react-icons/fa'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { useEcarts } from 'api'
import { getEcart } from 'store/analyse'
import { noDecimalFormat } from 'helpers/formatter'
import { useStyle } from 'hooks'
import { downloadCSV } from 'helpers/downloadFile'

const borderStyle = '1px solid rgb(0,0,0,.1)'
const rowHeight = 30

const isValid = (value: any) =>
  value !== null &&
  typeof value === 'number' &&
  ![Number.MAX_VALUE, Number.MIN_VALUE, Infinity, -Infinity].includes(value)

const formatValue = (value: any) => {
  if (!isValid(value)) return '-'
  return noDecimalFormat(value)
}

export const EcartsTable: React.FC<{
  setHovered: (hovered: string | null) => void
  hovered: string | null
  setSelected: (selected: number | null) => void
  selected: number | null
  className?: string
}> = ({ setHovered, hovered, setSelected, selected, ...props }) => {
  const { t } = useTranslation()
  const ref = React.useRef<HTMLDivElement>(null)
  const ecart = useSelector(getEcart)
  const ecarts = useEcarts()

  React.useEffect(() => {
    if (!ecarts) return
    const index = ecarts.LignesEntite.findIndex(({ Nom }) => Nom === hovered)
    if (index === -1) return
    ref?.current?.scrollTo({ top: rowHeight * index - 50, behavior: 'smooth' })
  }, [ecarts, hovered])

  React.useEffect(() => {
    if (selected === null) return
    const ids = ecarts?.LignesEntite.map((l) => l.Id) ?? []
    if (!ids.includes(selected)) setSelected(null)
  }, [ecarts, selected, setSelected])

  const css = useStyle((theme: any) => ({
    maxHeight: 460,
    width: '100%',
    overflow: 'auto',

    table: {
      backgroundColor: theme.gray100,
      margin: 0,
      borderCollapse: 'separate',
      borderSpacing: 0,
      border: 0,
      fontSize: 13,
    },

    'th, td': {
      border: 0,
      borderTop: borderStyle,
      borderLeft: borderStyle,
    },
    th: {
      verticalAlign: 'middle',
      // whiteSpace: 'nowrap',
    },
    td: {
      textAlign: 'right',
      width: 'calc(100% / 7)',
    },

    // sticky header
    thead: {
      tr: {
        th: {
          backgroundColor: theme.gray100,
          position: 'sticky !important',
          zIndex: 2,
          borderBottom: borderStyle,
          textAlign: 'center',
        },
        '&:nth-of-type(1)': {
          th: {
            top: 0,
          },
        },
      },
    },

    tbody: {
      cursor: 'pointer',
    },
  }))

  if (!ecarts) return null
  if (ecarts.LignesEntite.length === 0) return <div>{t('analyse.empty')}</div>

  const isBudget = [0, 2].includes(ecart)
  const isPct = [2, 3].includes(ecart)

  const header = [
    t('ecarts.perimetre'),
    isBudget ? t('ecarts.budget') : t('ecarts.n1'),
    t('ecarts.reel'),
    isBudget ? t('ecarts.ecartBudget') : t('ecarts.ecartN1'),
    t('ecarts.impactPrix'),
    t('ecarts.impactVolume'),
  ]

  const headerWithUnits = header.map((s: string, i) =>
    i === 0 ? s : `${s} (${(ecarts as any).Devise})`,
  )

  const body = ecarts.LignesEntite.map(({ Id, Nom, LignesMesure }) => {
    const item = LignesMesure[0].Data[0]

    const comparaison = isBudget ? item.Budget : item.ReelN1

    const ecart = isBudget
      ? isPct
        ? item.EcartPctBudget
        : item.EcartBudget
      : isPct
      ? item.EcartPctN1
      : item.EcartN1

    // const prixComparaison = isBudget ? item.PrixBudget : item.PrixReelN1
    // const impactVolume = item.Reel !== null && ecart !== null && prixComparaison !== null && prixComparaison !== 0 && (item.Reel - ecart) / prixComparaison
    const impactVolume = isBudget ? item.ImpactVolumeBudget : item.ImpactVolumeN1

    // const impactPrix = ecart !== null && impactVolume !== false && ecart - impactVolume
    const impactPrix = isBudget ? item.ImpactPrixBudget : item.ImpactPrixN1

    return {
      id: Id,
      cells: [
        Nom,
        formatValue(comparaison),
        formatValue(item.Reel),
        formatValue(ecart),
        formatValue(impactPrix),
        formatValue(impactVolume),
      ],
    }
  })

  const exportCSV = () => {
    const content = [
      headerWithUnits,
      ...body.map(({ cells: [th, ...tds] }) => [`"=""${th}"""`, ...tds]),
    ]
      .map((row) => row.join(';'))
      .join('\n')
    const date = moment().format('YYYY-MM-DD')
    const filename = `${t('ecarts.filename')} ${date}.csv`
    downloadCSV(filename, content)
  }

  return (
    <div {...props} className="w-100">
      <div className="d-flex justify-content-end mb-3">
        <Button color="secondary" onClick={exportCSV}>
          <FaFileExcel css={{ marginRight: '0.4rem', marginTop: -3 }} />
          {t('global.exportCsv')}
        </Button>
      </div>

      <div ref={ref} className="shadow-sm" css={css}>
        <Table striped bordered size="sm" className="border shadow-sm table-hover">
          <thead className="shadow-sm">
            <tr>
              {headerWithUnits.map((h, i) => (
                <th key={`${h}${i}`}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {body.map(({ id, cells }, index) => (
              <tr
                key={`${cells[0]}_${index}`}
                css={(theme: any) => ({
                  height: rowHeight,
                  ...(selected === id
                    ? {
                        backgroundColor: `${transparentize(0.6, theme.cyan)} !important`,
                      }
                    : hovered === cells[0]
                    ? {
                        backgroundColor: `${transparentize(0.6, theme.gold)} !important`,
                      }
                    : {}),
                })}
                // onMouseOver={() => {
                //   setHovered(Nom)
                // }}
                onClick={() => {
                  setHovered(null)
                  setSelected(id)
                }}
              >
                <th>{cells[0]}</th>
                <td>{cells[1]}</td>
                <td>{cells[2]}</td>
                <td>{cells[3]}</td>
                <td>{cells[4]}</td>
                <td>{cells[5]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
