import React from 'react'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import difference from 'lodash/difference'
import union from 'lodash/union'

import { useOrganisation } from 'api'
import { TreeSelect } from './TreeSelect'
import { TreeRow } from './TreeRow'
import { useStyle } from 'hooks'
import { createTree } from './createTree'
import { CountrySelect } from './CountrySelect'
import { EnergySelect } from './EnergySelect'
import { getLeafs } from './TreeSelect/getNodes'

const filterTree = (tree: any, s: string, countries: number[], energies: number[]) => {
  const matchText = s === '' || tree.value?.toLowerCase().includes(s) || false
  const matchCountry = countries.length === 0 || countries.includes(tree.country)
  const matchEnergy = energies.length === 0 || energies.includes(tree.energy)
  const matchAll = matchText && matchCountry && matchEnergy

  if (tree === null || !tree.children) {
    return matchAll ? tree : null
  } else if (matchAll) {
    return tree
  }

  const children = tree.children.reduce((acc: any[], child: any) => {
    const filteredChild = filterTree(child, s, countries, energies)
    if (filteredChild !== null) acc.push(filteredChild)
    return acc
  }, [])

  // (!matchText ||
  //   ('country' in tree && !matchCountry) ||
  //   ('energy' in tree && !matchEnergy)) &&
  return !matchAll && children.length === 0
    ? null
    : {
        ...tree,
        children,
      }
}

export const Organisation: React.FC<{
  values: number[] | null
  onChange: (values: number[]) => void
}> = ({ values, onChange }) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState('')
  const [countries, setCountries] = React.useState<number[]>([])
  const [energies, setEnergies] = React.useState<number[]>([])

  const orga = useOrganisation()
  const [originalTree, tree] = React.useMemo(() => {
    if (!orga) return [null, null]
    const tree = createTree(orga)
    if (inputValue === '' && countries.length === 0 && energies.length === 0) return [tree, tree]
    return [tree, filterTree(tree, inputValue.toLowerCase(), countries, energies)]
  }, [countries, energies, inputValue, orga])

  const selectAll = React.useCallback(() => onChange(union(values, getLeafs(tree))), [
    onChange,
    tree,
    values,
  ])

  const unselectAll = React.useCallback(() => onChange(difference(values, getLeafs(tree))), [
    onChange,
    tree,
    values,
  ])

  React.useEffect(() => {
    if (values === null) {
      onChange(getLeafs(tree))
    }
  }, [onChange, tree, values])

  const style = useStyle(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '>header': {
      padding: '1rem 4rem 1rem 1rem',
    },
    '>div': {
      flex: 1,
    },
    '.controls': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      label: {
        margin: 0,
        '>span': {
          width: 60,
        },
      },
      '>*:not(:last-child)': {
        marginBottom: '1rem',
      },
      '.select-buttons': {
        display: 'flex',
        button: {
          flex: 1,
          '&:not(:last-child)': {
            marginRight: '.8rem',
          },
        },
      },
    },
  }))

  if (values === null) return null

  return (
    <div css={style}>
      <header className="shadow-sm">
        <div className="controls">
          <CountrySelect values={countries} onChange={setCountries} />
          <EnergySelect values={energies} onChange={setEnergies} />
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t('sidebar.organisationPlaceholder')}
          />

          <div className="select-buttons">
            <Button color="light" onClick={selectAll}>
              {t('sidebar.selectAll')}
            </Button>
            <Button color="light" onClick={unselectAll}>
              {t('sidebar.unselectAll')}
            </Button>
          </div>
        </div>
      </header>

      <div>
        {tree && (
          <TreeSelect
            tree={[tree]}
            originalTree={[originalTree as any]}
            filtered={inputValue !== ''}
            values={values}
            onChange={onChange}
            nodeHeight={35}
            leafHeight={30}
            Row={TreeRow}
            showExpandButtons
          />
        )}
      </div>
    </div>
  )
}
