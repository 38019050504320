import React, { Suspense } from 'react'
import { FaUserCircle, FaChevronDown } from 'react-icons/fa'
import { Button, Spinner } from 'reactstrap'

import { useStyle } from 'hooks'

import { AppTabs } from './AppTabs'
import { ErrorBoundary } from 'components/atoms'
import { Options } from 'components/sections/Options'

export const AppMenu: React.FC<{
  toggleUserPanel: () => void
}> = ({ toggleUserPanel }) => {
  const style = useStyle(() => ({
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 2rem`,
    fontSize: '1.2rem',
    '.user-button': {
      opacity: 0.6,
      margin: '.8rem 0',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        opacity: 1,
      },
      '>*:not(:last-child)': {
        marginRight: '.6rem',
      },
    },
  }))

  return (
    <div css={style}>
      <div>
        <AppTabs />
      </div>

      <div className="d-flex">
        <div
          css={{
            minWidth: 100,
            marginRight: '2rem',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ErrorBoundary>
            <Suspense fallback={<Spinner color="white" style={{ opacity: 0.8 }} />}>
              <Options />
            </Suspense>
          </ErrorBoundary>
        </div>

        <Button onClick={toggleUserPanel} className="user-button shadow-none" color="primary">
          <FaUserCircle size={20} />
          <FaChevronDown />
        </Button>
      </div>
    </div>
  )
}
