import React from 'react'
import { Label, Button, Spinner, Form } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { addNotification } from 'notification'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { TextField } from 'components/form/TextField'
import { YearField } from 'components/form/YearField'
import { DateField } from 'components/form/DateField'
import { useStyle } from 'hooks'
import { useUpdateBudget, useBudgetDetail, useBudgetPerimetre, usePees } from 'api'
import { Organisation } from 'components/sections/Perimetre/Organisation'
import { BudgetPerimetreTable } from './BudgetPerimetreTable'
import { dateFormat } from 'helpers/formatter'

export const BudgetEdit: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const allPees = usePees()
  const budgetDetail = useBudgetDetail()
  const perimetre = useBudgetPerimetre()
  const initialValues = perimetre === null ? [] : Object.keys(perimetre).map((id) => Number(id))
  const [pees, setPees] = React.useState<number[]>(initialValues)
  const onPerimetreChange = React.useCallback((values: number[]) => setPees(values), [])

  const history = useHistory()
  const [mutate, { data: budget, status }] = useUpdateBudget()

  const schema = () =>
    Yup.object().shape({
      Nom: Yup.string().required(t('global.requiredField')),
      Date: Yup.string().required(t('global.requiredField')),
      Annee: Yup.number().required(t('global.requiredField')),
    })

  React.useEffect(() => {
    if (!budget) return
    setTimeout(done)
    history.push(`/budget/${budget.Id}`)
    addNotification({
      type: 'success',
      message: t('updateBudget.successMessage', { name: budget.Nom }),
    })
  }, [budget, done, history, t])

  const style = useStyle((theme: any) => ({
    position: 'relative',
    header: {
      marginBottom: '2rem',
    },
    form: {
      display: 'flex',
      alignItems: 'flex-start',
      '>*:not(:last-child)': {
        marginRight: '2rem',
      },
    },
    '.budget-fields': {
      paddingRight: '2rem',
      borderRight: `1px solid ${theme.gray400}`,
      flexShrink: 0,
    },
    'button[type=submit]': {
      marginTop: '1rem',
    },
  }))

  const values = {
    Nom: budgetDetail?.Nom || '',
    Date: budgetDetail ? moment(budgetDetail.DateCre).format(dateFormat()) : null,
    Annee: budgetDetail?.Annee,
  }

  const handleSubmit = React.useCallback(
    async (values, actions) => {
      try {
        await mutate({
          ...values,
          Perimetre: pees.map((id) => ({ id, peeNrjId: allPees[id].PeeNrjId })),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('updateBudget.errorTitle'),
          message: (error as any).toString(),
        })
      } finally {
        actions.setSubmitting(false)
      }
    },
    [allPees, mutate, pees, t],
  )

  return (
    <div css={style}>
      <header>
        <h5>
          {t('budget.budget')} <strong>{budgetDetail?.Nom}</strong>
        </h5>
      </header>

      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <div className="budget-fields">
              <TextField name="Nom" label={t('updateBudget.name')} />
              <DateField name="Date" label={t('updateBudget.date')} />
              <YearField name="Annee" label={t('updateBudget.year')} />
              <Button
                disabled={
                  status === 'loading' ||
                  props.isSubmitting ||
                  (Object.keys(props.touched).length === 0 &&
                    pees.join(',') === initialValues.join(','))
                }
                type="submit"
              >
                {status === 'loading' && <Spinner size="sm" className="mr-2" />}
                {t('updateBudget.submitButton')}
              </Button>
            </div>

            <div>
              <Label>{t('budget.pdl')}</Label>
              <div
                css={{
                  height: '60vh',
                  width: 400,
                  header: { margin: '.8rem 0', padding: 0, boxShadow: 'none !important' },
                }}
              >
                <Organisation values={pees} onChange={onPerimetreChange} />
              </div>
            </div>

            <div css={{ flexGrow: 1, overflowX: 'auto' }}>
              <BudgetPerimetreTable values={pees} onChange={onPerimetreChange} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
