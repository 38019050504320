import { combineReducers, AnyAction } from '@reduxjs/toolkit'
import { reducer as oidc } from 'redux-oidc'

import { userReducer as user } from 'commons'
import { analyse } from 'store/analyse'
import { budget } from 'store/budget'
import { api } from 'store/api'

const reducer = combineReducers({
  oidc,
  user,
  api,
  analyse,
  budget,
})

export type RootState = ReturnType<typeof reducer>

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'ebudget/LOAD_PRESISTED_STATE') {
    const { oidc, user, api } = state
    state = {
      ...action.persistedState,
      oidc,
      user,
      api,
    }
  }

  return reducer(state, action)
}
