import React, { Suspense } from 'react'
import { FaPlus, FaTrashAlt, FaSave } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Spinner,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { useBoolean } from 'hooks'
import { useVersions } from 'api'
import { Dialog } from 'components/atoms'
import { VersionSelect } from './VersionSelect'
import { VersionCreate } from './VersionCreate'
import { VersionSave } from './VersionSave'
import { VersionSaveNew } from './VersionSaveNew'
import { VersionDelete } from './VersionDelete'

export const BudgetFiltersVersions: React.FC = () => {
  const { t } = useTranslation()
  const [isVersionCreateOpen, toggleVersionCreate, closeVersionCreate] = useBoolean(false)
  const [isVersionSaveOpen, toggleVersionSave, closeVersionSave] = useBoolean(false)
  const [isVersionSaveNewOpen, toggleVersionSaveNew, closeVersionSaveNew] = useBoolean(false)
  const [isVersionDeleteOpen, toggleVersionDelete, closeVersionDelete] = useBoolean(false)
  const [isDropdownOpen, toggleDropdown] = useBoolean(false)

  const versions = useVersions()

  return (
    <>
      <div className="form-version">
        <Suspense fallback={<Spinner color="primary" />}>
          <VersionSelect />

          <Button title={t('budget.createVersion')} onClick={toggleVersionCreate}>
            <FaPlus />
          </Button>

          <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret disabled={false}>
              <FaSave />
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem onClick={toggleVersionSave}>
                <FaSave />
                {t('budget.saveVersion')}
              </DropdownItem>

              <DropdownItem onClick={toggleVersionSaveNew}>
                <FaPlus />
                {t('budget.saveNewVersion')}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>

          {versions && versions.length > 1 && (
            <Button onClick={toggleVersionDelete} color="danger" title={t('budget.deleteVersion')}>
              <FaTrashAlt />
            </Button>
          )}
        </Suspense>
      </div>

      <Dialog
        isOpen={isVersionCreateOpen}
        close={closeVersionCreate}
        css={{ width: 500, height: 450 }}
      >
        {isVersionCreateOpen && <VersionCreate done={closeVersionCreate} />}
      </Dialog>

      <Dialog isOpen={isVersionSaveOpen} close={closeVersionSave} css={{ width: 500, height: 450 }}>
        {isVersionSaveOpen && <VersionSave done={closeVersionSave} />}
      </Dialog>

      <Dialog
        isOpen={isVersionSaveNewOpen}
        close={closeVersionSaveNew}
        css={{ width: 500, height: 450 }}
      >
        {isVersionSaveNewOpen && <VersionSaveNew done={closeVersionSaveNew} />}
      </Dialog>

      <Dialog isOpen={isVersionDeleteOpen} close={closeVersionDelete} css={{ width: 400 }}>
        {isVersionDeleteOpen && <VersionDelete done={closeVersionDelete} />}
      </Dialog>
    </>
  )
}
