import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { usePreferences, usePreferencesReferentiel, useUpdatePreferences } from 'api'
import { Select } from 'components/atoms/Select'
import { addNotification } from 'notification'

export const Options = () => {
  const { t } = useTranslation()
  const { nrjUnits, devises } = usePreferencesReferentiel()
  const pref = usePreferences()
  const [mutate, { status }] = useUpdatePreferences()

  const [devise, setDevise] = useState<number | null>(null)
  const [nrjUnit, setNrjUnit] = useState<number | null>(null)

  useEffect(() => {
    pref && setDevise(pref.CurrencyUI)
  }, [pref])

  useEffect(() => {
    pref && setNrjUnit(pref.EnergyUnitUI)
  }, [pref])

  return (
    <div
      css={{
        fontSize: 14,
        display: 'flex',
        '>*': {
          width: 150,
          margin: 0,
          '&:not(:last-child)': {
            marginRight: '1rem',
          },
          '.label': {
            color: '#fff',
          },
          '.selection': {
            maxWidth: 'unset',
          },
        },
      }}
    >
      <Select
        label={t('global.currency')}
        inline
        value={devise}
        options={devises}
        disabled={status === 'loading' || devise === null}
        closeOnSelect
        onChange={async (value) => {
          setDevise(value)
          try {
            await mutate([{ op: 'replace', path: 'CurrencyUI', value }])
          } catch (error) {
            setDevise(pref?.CurrencyUI ?? null)
            addNotification({
              type: 'danger',
              message: (error as any).toString(),
            })
          }
        }}
        inputProps={{
          bsSize: 'sm',
        }}
      />

      <Select
        label={t('global.nrjUnit')}
        inline
        value={nrjUnit}
        options={nrjUnits}
        disabled={status === 'loading' || nrjUnit === null}
        closeOnSelect
        onChange={async (value) => {
          setNrjUnit(value)
          try {
            await mutate([{ op: 'replace', path: 'EnergyUnitUI', value }])
          } catch (error) {
            setNrjUnit(pref?.EnergyUnitUI ?? null)
            addNotification({
              type: 'danger',
              message: (error as any).toString(),
            })
          }
        }}
        inputProps={{
          bsSize: 'sm',
        }}
      />
    </div>
  )
}
