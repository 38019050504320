import React, { FC } from 'react'
import { useField, useFormikContext } from 'formik'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'

export const TextField: FC<{
  name: string
  label: string
  type?: 'text' | 'number'
  size?: 'sm' | 'lg'
  disabled?: boolean
}> = ({ name, type = 'text', label, disabled = false, size, ...props }) => {
  const { isSubmitting, setTouched, touched } = useFormikContext()
  const [{ onChange, value, ...field }, meta] = useField(name)

  return (
    <FormGroup {...props}>
      <Label size={size} for={name}>
        {label}
      </Label>

      <Input
        id={name}
        bsSize={size}
        type={type}
        value={value || ''}
        invalid={!!meta.error && !!meta.touched}
        disabled={disabled || isSubmitting}
        onChange={(x) => {
          onChange(x)
          setTouched({ ...touched, [name]: true }, false)
        }}
        {...field}
      />
      {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
    </FormGroup>
  )
}
