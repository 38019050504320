import React from 'react'
import { motion } from 'framer-motion'

export const Collapse: React.FC<{ isOpen: boolean }> = ({ isOpen, children, ...props }) => {
  return (
    <motion.div
      style={{
        overflow: 'hidden',
      }}
      initial="close"
      animate={isOpen ? 'open' : 'close'}
      variants={{
        open: {
          // opacity: 1,
          height: '100%',
        },
        close: {
          // opacity: 0,
          height: 0,
          transitionEnd: {
            display: 'none',
          },
        },
      }}
      {...props}
    >
      {children}
    </motion.div>
  )
}
