import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Input, Button, Spinner } from 'reactstrap'
import { queryCache } from 'react-query'
import { useTranslation } from 'react-i18next'

import { useDeleteBudget, useBudgetName } from 'api'
import { getBudgetId, setBudgetId } from 'store/budget'
import { addNotification } from 'notification'
import { useStyle } from 'hooks'

export const BudgetDelete: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const [mutate, { status }] = useDeleteBudget()
  const history = useHistory()
  const dispatch = useDispatch()
  const budgetId = useSelector(getBudgetId)
  const [input, setInput] = React.useState<string>('')

  const budgetName = useBudgetName()
  const match = input === budgetName

  const handleClick = React.useCallback(async () => {
    if (budgetId === null) return null
    try {
      await mutate({ budgetId })
      done()
      await queryCache.setQueryData(['versions', { budgetId }], null)
      history.push('/budget')
      dispatch(setBudgetId(null))
      addNotification({
        type: 'success',
        message: t('deleteBudget.successMessage', { name: budgetName }),
      })
    } catch (error) {
      addNotification({
        type: 'danger',
        title: t('deleteBudget.errorTitle'),
        message: (error as any).toString(),
      })
    }
  }, [budgetId, budgetName, dispatch, done, history, mutate, t])

  const style = useStyle((theme: any) => ({
    '>*:not(:last-child)': {
      marginBottom: '1.5rem',
    },
  }))

  return (
    <div css={style}>
      <p>
        {t('deleteBudget.p1')}
        <br />
        {t('deleteBudget.p2')}
      </p>
      <p>
        {t('deleteBudget.p3')} <strong style={{ whiteSpace: 'nowrap' }}>{budgetName}</strong>{' '}
        {t('deleteBudget.p4')}
      </p>

      <p>
        {t('deleteBudget.confirm1')} <strong style={{ whiteSpace: 'nowrap' }}>{budgetName}</strong>{' '}
        {t('deleteBudget.confirm2')}
      </p>

      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled={status === 'loading'}
      />

      <Button
        color={match ? 'danger' : 'light'}
        disabled={!match || status === 'loading'}
        onClick={handleClick}
        css={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {status === 'loading' ? (
          <>
            <Spinner size="sm" style={{ marginRight: '.6rem' }} />
            <span>{t('deleteBudget.progress')}</span>
          </>
        ) : (
          <span>{t('deleteBudget.button')}</span>
        )}
      </Button>
    </div>
  )
}
