import React from 'react'
import { MultiSelect } from 'components/atoms'
import { useTranslation } from 'react-i18next'

import { useCountryOptions } from 'api'

export const CountrySelect: React.FC<{
  values: number[]
  onChange: (values: number[]) => void
}> = ({ values, onChange }) => {
  const { t } = useTranslation()
  const options = useCountryOptions()
  const optionsWithIcons = options.map((option) => ({
    ...option,
    icon: <span className={`flag-icon flag-icon-${option.code.toLowerCase()}`} />,
  }))

  return (
    <MultiSelect
      options={optionsWithIcons}
      values={values}
      onChange={onChange}
      label={t('global.country')}
      inline
      clearable
    />
  )
}
