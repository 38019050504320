export const getLeafs = (tree: any) => {
  const leafs: any = []

  const createLeafs = (tree: any, leafs: any) => {
    if (!tree) return
    if (!tree.children) {
      leafs.push(tree.id)
    } else {
      tree.children.forEach((child: any) => {
        createLeafs(child, leafs)
      })
    }
  }

  createLeafs(tree, leafs)

  return leafs
}

export const getNodes = (tree: any) => {
  const nodes: any = {}

  const createNodes = (parent: any, depth: any, tree: any) => {
    nodes[tree.id] = {
      id: tree.id,
      parent,
      value: 0,
      depth,
    }
    if (tree.children) {
      nodes[tree.id].children = tree.children.map((child: any) => child.id)
      nodes[tree.id].count = null
      nodes[tree.id].status = null
      nodes[tree.id].leafs = getLeafs(tree)
      tree.children.forEach((child: any) => {
        createNodes(tree.id, depth + 1, child)
      })
    }
  }

  tree.forEach((node: any) => {
    createNodes(null, 0, node)
  })

  return nodes
}
