import React from 'react'
import { useQuery, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import has from 'lodash/has'
import uniqBy from 'lodash/uniqBy'
import flatten from 'lodash/flatten'

import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getLanId, getUorIdMst, getUsrId } from 'store/oidc'

const ONE_DAY = 86400000

export const isUor = (node: OrgaNode): node is Uor => (node as Uor).UorNom !== undefined

export const useOrganisation = () => {
  const ready = useSelector(getReady)
  const lanId = useSelector(getLanId)
  const uorIdMst = useSelector(getUorIdMst)
  const usrId = useSelector(getUsrId)

  const { data } = useQuery<Orga, AnyQueryKey>(
    ready && lanId && uorIdMst && usrId && ['organisation', { lanId, uorIdMst, usrId }],
    () => superFetch({ url: `organisation?usrid=${usrId}&uoridmst=${uorIdMst}&lanId=${lanId}` }),
    { staleTime: ONE_DAY },
  )

  return data
}

export const usePees = () => {
  const organisation = useOrganisation()

  const pees = React.useMemo(() => {
    let tree: any = {}
    if (!organisation) return tree

    const createTree = (node: OrgaNode) => {
      if (isUor(node)) {
        node.UorEnfants?.forEach?.(createTree)
        node.UorSites?.forEach?.(createTree)
      } else {
        node.SitPees?.forEach?.((pee) => {
          tree[pee.id] = {
            ...pee,
            SphNom: node.SphNom,
            UorNom: node.Organisation.UorNom,
          }
        })
      }
    }
    createTree(organisation)
    return tree
  }, [organisation])

  return pees
}

export const useCountryOptions = () => {
  const orga = useOrganisation()

  const options = React.useMemo(() => {
    const getCountries = (node: any) => {
      if (has(node, 'PeeClef'))
        return [
          {
            value: node.IdPays,
            code: node.CodePays,
            label: node.LibellePays,
          },
        ]
      const countries = []
      if (has(node, 'SitPees')) countries.push(...node.SitPees.map(getCountries))
      if (has(node, 'UorEnfants')) countries.push(...node.UorEnfants.map(getCountries))
      if (has(node, 'UorSites')) countries.push(...node.UorSites.map(getCountries))
      return uniqBy(flatten(countries), 'value')
    }

    return getCountries(orga)
  }, [orga])

  return options
}
