import React, { FC } from 'react'
import { Spinner } from 'reactstrap'

export const Loader: FC<{
  color?: string
}> = ({ color = 'primary', ...props }) => {
  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    >
      <Spinner color={color} />
    </div>
  )
}
