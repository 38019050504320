import React from 'react'
import { Label, Button, Spinner, Form } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { addNotification } from 'notification'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { TextField } from 'components/form/TextField'
import { YearField } from 'components/form/YearField'
import { DateField } from 'components/form/DateField'
import { useStyle } from 'hooks'
import { useCreateBudget, usePees } from 'api'
import { Organisation } from 'components/sections/Perimetre/Organisation'
import { BudgetPerimetreTable } from './BudgetPerimetreTable'
import { dateFormat } from 'helpers/formatter'

export const BudgetCreate: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const allPees = usePees()
  const [pees, setPees] = React.useState<number[]>([])
  const onPerimetreChange = React.useCallback((values: number[]) => setPees(values), [])

  const history = useHistory()
  const [mutate, { data: budget, status }] = useCreateBudget()

  const schema = () =>
    Yup.object().shape({
      Nom: Yup.string().required(t('global.requiredField')),
      Date: Yup.string().required(t('global.requiredField')),
      Annee: Yup.number().required(t('global.requiredField')),
    })

  React.useEffect(() => {
    if (!budget) return
    setTimeout(done)
    history.push(`/budget/${budget.Id}`)
    addNotification({
      type: 'success',
      title: t('createBudget.successTitle'),
      message: t('createBudget.successMessage', { name: budget.Nom }),
    })
  }, [budget, done, history, t])

  const style = useStyle((theme: any) => ({
    position: 'relative',
    header: {
      marginBottom: '2rem',
    },
    form: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    '.budget-fields': {
      paddingRight: '2rem',
      borderRight: `1px solid ${theme.gray400}`,
      flexShrink: 0,
    },
    'button[type=submit]': {
      marginTop: '1rem',
    },
  }))

  const values = {
    Nom: '',
    Date: moment().format(dateFormat()),
    Annee: moment().format('YYYY'),
  }

  const handleSubmit = React.useCallback(
    async (values, actions) => {
      try {
        await mutate({
          ...values,
          Date: moment(values.Date, dateFormat()).format('DD/MM/YYYY'),
          Perimetre: pees.map((id) => ({ id, peeNrjId: allPees[id].PeeNrjId })),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('createBudget.errorTitle'),
          message: (error as any).toString(),
        })
      } finally {
        actions.setSubmitting(false)
      }
    },
    [allPees, mutate, pees, t],
  )

  return (
    <div css={style}>
      <header>
        <h5>{t('createBudget.title')}</h5>
      </header>

      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <div className="budget-fields">
              <TextField name="Nom" label={t('updateBudget.name')} />
              <DateField name="Date" label={t('updateBudget.date')} />
              <YearField name="Annee" label={t('updateBudget.year')} />
              <Button
                disabled={
                  status === 'loading' ||
                  props.isSubmitting ||
                  Object.keys(props.touched).length === 0
                }
                type="submit"
              >
                {status === 'loading' && <Spinner size="sm" className="mr-2" />}
                {t('createBudget.submitButton')}
              </Button>
            </div>

            <div style={{ paddingLeft: '1rem' }}>
              <Label>{t('budget.pdl')}</Label>
              <div
                css={{
                  height: '60vh',
                  width: 380,
                  header: { margin: '.8rem 0', padding: 0, boxShadow: 'none !important' },
                }}
              >
                <Organisation values={pees} onChange={onPerimetreChange} />
              </div>
            </div>

            <div css={{ flexGrow: 1 }}>
              <BudgetPerimetreTable values={pees} onChange={onPerimetreChange} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
