import React from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { AppLayout } from 'components/templates/AppLayout'
import { AnalyseLayout } from 'components/templates/AnalyseLayout'
import { BudgetLayout } from 'components/templates/BudgetLayout'

import { AnalysePageSuivi } from 'components/templates/AnalysePageSuivi'
import { AnalysePageAtterrissage } from 'components/templates/AnalysePageAtterrissage'
import { AnalysePageEcarts } from 'components/templates/AnalysePageEcarts'
import { BudgetPage } from 'components/templates/BudgetPage'

export const AppRoutes = () => {
  const history = useHistory()

  React.useEffect(() => {
    const pathname = localStorage.getItem('ebudget-location')
    if (pathname === null) return
    localStorage.removeItem('ebudget-location')
    history.push(pathname === '/' ? '/analyse/suivi-mensuel' : pathname)
  }, [history])

  return (
    <AppLayout>
      <Switch>
        <Redirect exact from="/" to="/analyse/suivi-mensuel" />

        <Route path="/budget/:budget/version/:version">
          <BudgetLayout>
            <BudgetPage />
          </BudgetLayout>
        </Route>

        <Route path="/budget/:budget">
          <BudgetLayout>
            <BudgetPage />
          </BudgetLayout>
        </Route>

        <Route path="/budget">
          <BudgetLayout>
            <BudgetPage />
          </BudgetLayout>
        </Route>

        <Route path="/analyse">
          <AnalyseLayout>
            <Switch>
              <Route path="/analyse/suivi-mensuel">
                <AnalysePageSuivi />
              </Route>

              <Route path="/analyse/atterrissage">
                <AnalysePageAtterrissage />
              </Route>

              <Route path="/analyse/ecarts">
                <AnalysePageEcarts />
              </Route>
            </Switch>
          </AnalyseLayout>
        </Route>
      </Switch>
    </AppLayout>
  )
}
