import React, { useState, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap'
import { FaPowerOff } from 'react-icons/fa'

import Security from './Security'
import UserInfos from './UserInfos'
import { logout } from '../../store/user'

export const UserPanel: FC<{ done: () => void }> = ({ done }) => {
  const [activeTab, setActiveTab] = useState('1')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((store: any) => store.oidc.user)

  return (
    <div
      css={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <header
        css={{
          height: 70,
          display: 'flex',
          alignItems: 'flex-end',
          backgroundColor: '#ced4da',
          '.nav': {
            width: '100%',
            padding: '0 15px',
            borderBottom: '1px solid #adb5bd',
          },
          '.nav-item': {
            marginBottom: -1,
          },
          '.nav-link': {
            cursor: 'pointer',
            backgroundColor: 'transparent !important',
            '&.active': {
              backgroundColor: '#e9ecef !important',
              border: '1px solid #adb5bd',
              borderBottom: '1px solid #e9ecef',
              borderTopLeftRadius: '0.25rem',
              borderTopRightRadius: '0.25rem',
            },
          },
        }}
      >
        <Nav>
          <NavItem>
            <NavLink
              {...(activeTab === '1' && { className: 'active' })}
              onClick={() => setActiveTab('1')}
            >
              Utilisateur
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              {...(activeTab === '2' && { className: 'active' })}
              onClick={() => setActiveTab('2')}
            >
              Sécurité
            </NavLink>
          </NavItem>
        </Nav>

        <Button
          color="danger"
          onClick={() => dispatch(logout(user))}
          css={{ position: 'absolute', right: 35, margin: 15, top: 0 }}
        >
          <FaPowerOff css={{ marginRight: '0.6rem' }} />
          {t('user.logout')}
        </Button>
      </header>

      <TabContent
        activeTab={activeTab}
        css={{
          height: '100%',
          '.tab-pane': {
            height: '100%',
          },
        }}
      >
        <TabPane tabId="1">
          <UserInfos done={done} />
        </TabPane>
        <TabPane tabId="2">
          <Security done={done} />
        </TabPane>
      </TabContent>
    </div>
  )
}
