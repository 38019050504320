import React, { FC } from 'react'
import { useField, useFormikContext } from 'formik'
import { FormFeedback } from 'reactstrap'
import moment from 'moment'

import { DateInput } from 'components/atoms'

const format = 'YYYY'

export const YearField: FC<{
  name: string
  label: string
  type?: 'text' | 'number'
  size?: 'sm' | 'lg'
  disabled?: boolean
}> = ({ name, type = 'text', label, disabled = false, size, ...props }) => {
  const { setFieldValue, isSubmitting, setTouched, touched } = useFormikContext()
  const [{ onChange, value, ...field }, meta] = useField(name)

  return (
    <div {...props}>
      <DateInput
        label={label}
        value={value ? moment(value, format).format() : null}
        onChange={(date) => {
          const dateValue = moment(date, format).format(format)
          setFieldValue(field.name, dateValue)
          setTouched({ ...touched, [name]: true }, false)
        }}
        inputProps={{
          disabled: disabled || isSubmitting,
          invalid: !!meta.error && !!meta.touched,
        }}
        view="years"
        format={format}
      />

      {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
    </div>
  )
}
