import React from 'react'
import { VariableSizeTree as Tree } from 'react-vtree'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from 'react-i18next'
import { FaAngleRight, FaAngleDown } from 'react-icons/fa'
import { Button } from 'reactstrap'

import { Node, ExtendedData, RowProps } from './types'
import { getNodes } from './getNodes'

export const TreeSelect: React.FC<{
  tree: Node[]
  originalTree?: Node[]
  values: number[]
  onChange: (values: number[]) => void
  checkedOnly?: boolean
  nodeHeight: number
  leafHeight: number
  Row: React.FC<RowProps>
  isOpenByDefault?: boolean
  showExpandButtons?: boolean
  filtered?: boolean
}> = React.memo(
  ({
    tree,
    originalTree = tree,
    values,
    onChange,
    nodeHeight,
    leafHeight,
    checkedOnly = false,
    Row,
    isOpenByDefault = true,
    showExpandButtons = false,
    filtered = false,
  }) => {
    const { t } = useTranslation()
    const originalOpened = [tree[0].id]
    const opened = React.useRef<number[]>(originalOpened)
    const ref = React.useRef(null)
    const nodes = React.useMemo(() => {
      return getNodes(tree)
    }, [tree])

    React.useEffect(() => {
      if (!ref.current || filtered) return
      opened.current = originalOpened
      ;(ref.current as any).recomputeTree({
        refreshNodes: true,
        useDefaultOpenness: true,
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtered])

    function* treeWalker(refresh: any) {
      const stack = []

      const filteredTree = checkedOnly
        ? tree.filter((node) => !node?.children?.every((child: any) => !values.includes(child.id)))
        : tree

      stack.push(
        ...filteredTree.map((node) => ({
          nestingLevel: 0,
          node,
        })),
      )

      while (stack.length !== 0) {
        const { node, nestingLevel } = stack.pop() as any
        const id: any = node.id.toString()
        const isLeaf = !node.children

        const isOpened: boolean = yield refresh
          ? {
              defaultHeight: isLeaf ? leafHeight : nodeHeight,
              id,
              isLeaf,
              isOpenByDefault: filtered
                ? true
                : isOpenByDefault && opened.current.includes(node.id),
              name: node.name,
              nestingLevel,
              values,
              onChange,
              nodes,
            }
          : id

        if (node.children && node.children.length !== 0 && isOpened) {
          for (let i = node.children.length - 1; i >= 0; i--) {
            const child = node.children[i]
            const isLeaf = !child.children

            if (checkedOnly && isLeaf && !values.includes(child.id)) continue

            stack.push({
              nestingLevel: nestingLevel + 1,
              node: child,
            })
          }
        }
      }
    }

    return (
      <>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Tree<ExtendedData> ref={ref} treeWalker={treeWalker} height={height} width="100%">
              {Row}
            </Tree>
          )}
        </AutoSizer>

        {!filtered && showExpandButtons && (
          <div
            css={{
              position: 'absolute',
              top: 0,
              right: 0,
              display: 'flex',
              margin: 10,
              '>*': { marginRight: 6 },
            }}
          >
            <Button
              size="sm"
              color="light"
              onClick={() => {
                if (!ref.current) return
                opened.current = Object.values(nodes).map((node: any) => node.id)
                setTimeout(() => {
                  ;(ref.current as any).recomputeTree({
                    refreshNodes: true,
                    useDefaultOpenness: true,
                  })
                  opened.current = originalOpened
                }, 100)
              }}
              title={t('sidebar.expandAll')}
            >
              <FaAngleDown size={16} />
            </Button>

            <Button
              size="sm"
              color="light"
              onClick={() => {
                if (!ref.current) return
                opened.current = []
                setTimeout(() => {
                  ;(ref.current as any).recomputeTree({
                    refreshNodes: true,
                    useDefaultOpenness: true,
                  })
                  opened.current = originalOpened
                }, 100)
              }}
              title={t('sidebar.collapseAll')}
            >
              <FaAngleRight size={16} />
            </Button>
          </div>
        )}
      </>
    )
  },
)
