/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'
import matchSorter from 'match-sorter'
import { motion } from 'framer-motion'
import { FormGroup, Input, Label, Badge } from 'reactstrap'
import { useRect } from '@reach/rect'

import caret from 'images/caret.svg'
// import search from 'images/search.svg'

interface Item {
  value: number
  label: string
  icon?: React.ReactChild
  disabled?: boolean
}

const getItems = (items: Item[], filter: string | null) =>
  filter
    ? matchSorter(items, filter, {
        keys: ['label'],
      })
    : items

const itemToString = (item: Item | null) => (item ? item.label : '')

const stateReducer = (state: DownshiftState<Item>, changes: StateChangeOptions<Item>) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true,
        inputValue: '',
      }
    default:
      return changes
  }
}

export const MultiSelect: React.FC<{
  options: Item[]
  values: number[]
  onChange: (values: number[]) => void
  placeholder?: string
  inputProps?: React.ComponentProps<typeof Input>
  label?: string
  inline?: boolean
  clearable?: boolean
  displayMax?: number
}> = ({
  options: items,
  values,
  onChange,
  placeholder,
  inputProps,
  label,
  inline = false,
  clearable = false,
  displayMax = 3,
  ...extraProps
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const selRef = React.useRef<HTMLDivElement>(null)
  const rect = useRect(selRef)

  const selectedItems = items.filter((item) => values.includes(item.value))

  const setItems = (items: Item[]) => {
    onChange(items.map((item) => item.value))
  }

  const handleSelection = (selectedItem: Item | null) => {
    if (selectedItem === null) return
    if (selectedItems.some((item) => item.value === selectedItem.value)) {
      removeItem(selectedItem)
    } else {
      addSelectedItem(selectedItem)
    }
  }

  const removeItem = (item: Item) => {
    setItems(selectedItems.filter((i) => i.value !== item.value))
  }

  const addSelectedItem = (item: Item) => {
    setItems([...selectedItems, item])
  }

  const Comp = (
    <Downshift<Item>
      stateReducer={stateReducer}
      onChange={handleSelection}
      selectedItem={null}
      itemToString={itemToString}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        toggleMenu,
      }) => (
        <div style={{ position: 'relative', width: '100%' }} {...(!label && extraProps)}>
          <Input
            {...getInputProps()}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && !inputValue) {
                removeItem(selectedItems[selectedItems.length - 1])
              }
            }}
            innerRef={inputRef}
            placeholder={placeholder || ' '}
            onClick={() => {
              toggleMenu()
              if (!isOpen) inputRef?.current?.focus()
            }}
            css={{
              paddingLeft: (rect?.width || 0) + 5,
              paddingRight: `calc(1.5rem + 16px)`,
              background: `#fff url(${caret}) no-repeat right 0.75rem center`,
              backgroundSize: '1.1em',
              cursor: 'pointer',
            }}
            {...inputProps}
          />

          <div
            ref={selRef}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              maxWidth: 'calc(100% - 60px)',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 5,
              fontSize: 16,
              '>*': {
                maxWidth: 150,
                cursor: 'pointer',
                userSelect: 'none',
                textAlign: 'left',
                position: 'relative',
                ...((clearable || selectedItems.length > 1) && {
                  '&:hover': {
                    ':before': {
                      content: '"\u00d7 "',
                    },
                  },
                }),
                '&:not(:last-child)': {
                  marginRight: 5,
                },
              },
              '.badge': {
                backgroundColor: '#ced4da99',
              },
            }}
          >
            {selectedItems.slice(0, displayMax).map((item) => (
              <Badge
                key={item.value}
                color="light"
                className="text-nowrap text-truncate"
                title={item.label}
                {...((clearable || selectedItems.length > 1) && {
                  onClick: (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    removeItem(item)
                  },
                })}
              >
                {item.icon || item.label}
              </Badge>
            ))}
            {selectedItems.length > displayMax && (
              <Badge
                color="light"
                className="text-nowrap text-truncate"
                title={selectedItems
                  .slice(displayMax)
                  .map((item) => item.label)
                  .join(', ')}
                css={{
                  flexShrink: 0,
                  '&:hover': {
                    ':before': {
                      display: 'none',
                    },
                  },
                }}
              >
                +{selectedItems.length - displayMax}
              </Badge>
            )}
          </div>

          <motion.div
            className="shadow"
            css={{
              maxHeight: 200,
              width: '100%',
              overflowY: 'auto',
              position: 'absolute',
              zIndex: 100,
              ul: {
                margin: 0,
                padding: 0,
                listStyleType: 'none',
              },
            }}
            animate={isOpen ? 'open' : 'close'}
            initial={false}
            variants={{
              open: {
                height: 'auto',
                display: 'block',
              },
              close: {
                height: 0,
                transitionEnd: {
                  display: 'none',
                },
              },
            }}
            transition={{
              duration: 0.2,
              ease: 'easeOut',
            }}
          >
            <ul {...getMenuProps()}>
              {getItems(items, inputValue).map((item, index) => {
                const isSelected = selectedItems.some(({ value }) => value === item.value)
                const { onClick, itemProps } = getItemProps({ index, item })
                return (
                  <li
                    key={item.value}
                    {...(!item.disabled && itemProps)}
                    onClick={(e) => {
                      e.preventDefault()
                      onClick(e)
                    }}
                    css={(theme: any) => ({
                      padding: '4px 8px 4px 8px',
                      cursor: 'pointer',
                      '&:not(:last-child)': {
                        borderBottom: '1px solid #eee',
                      },
                      backgroundColor: highlightedIndex === index ? theme.gray200 : '#fff',
                      fontWeight: isSelected ? 'bold' : 'normal',
                      label: {
                        marginLeft: 4,
                      },
                    })}
                  >
                    <div
                      className="custom-control custom-checkbox"
                      style={{ pointerEvents: 'none' }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`${item.value}${index}`}
                        checked={isSelected}
                        onChange={() => {}}
                        disabled={item.disabled}
                      />
                      <label className="custom-control-label" htmlFor={`${item.value}${index}`}>
                        {item.icon}
                        <span className="ml-2">{item.label}</span>
                      </label>
                    </div>
                  </li>
                )
              })}
            </ul>
          </motion.div>
        </div>
      )}
    </Downshift>
  )

  return (
    <FormGroup css={{ marginBottom: '.7rem' }} {...extraProps}>
      {!label ? (
        Comp
      ) : (
        <Label
          css={
            inline
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  '>span': {
                    flexShrink: 0,
                    margin: 0,
                    marginRight: '.6rem',
                  },
                }
              : {
                  marginBottom: '.7rem',
                  '>span': {
                    display: 'inline-block',
                    marginBottom: '.5rem',
                  },
                }
          }
        >
          <span className="label">{label}</span>
          {Comp}
        </Label>
      )}
    </FormGroup>
  )
}
