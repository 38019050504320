interface Option {
  value: number
  label: string
}

type T = (s: string) => string

type OptionsCreator = (t: T) => Option[]

export const energiesRef: OptionsCreator = (t) => [
  { value: 1, label: t('global.gas') },
  { value: 2, label: t('global.electricity') },
]

export const naturesRef: OptionsCreator = (t) => [
  { value: 0, label: t('referentiel.nature.vol') },
  { value: 1, label: t('referentiel.nature.nrj') },
  { value: 2, label: t('referentiel.nature.tpt') },
  { value: 3, label: t('referentiel.nature.tax') },
  { value: 4, label: t('referentiel.nature.aut') },
  { value: 5, label: t('referentiel.nature.tot') },
]

export const aggregationsRef: OptionsCreator = (t) => [
  { value: 0, label: t('referentiel.aggregation.compteur') },
  { value: 1, label: t('referentiel.aggregation.site') },
  { value: 3, label: t('referentiel.aggregation.bu') },
  { value: 2, label: t('referentiel.aggregation.agrege') },
]

export const indicatorsRef: OptionsCreator = (t) => [
  { value: 0, label: t('referentiel.indicateur.reel') },
  { value: 1, label: t('referentiel.indicateur.budget') },
  { value: 7, label: t('referentiel.indicateur.facture') },
  { value: 2, label: t('referentiel.indicateur.n1') },
  { value: 3, label: t('referentiel.indicateur.ecartBudget') },
  { value: 4, label: t('referentiel.indicateur.ecartBudgetPct') },
  { value: 5, label: t('referentiel.indicateur.ecartN1') },
  { value: 6, label: t('referentiel.indicateur.ecartN1Pct') },
]

export const suiviIndicatorsRef: OptionsCreator = (t) => [
  { value: 0, label: t('referentiel.indicateur.reelPrevu') },
  { value: 1, label: t('referentiel.indicateur.budget') },
  { value: 7, label: t('referentiel.indicateur.facture') },
  { value: 2, label: t('referentiel.indicateur.n1') },
  { value: 3, label: t('referentiel.indicateur.ecartBudget') },
  { value: 4, label: t('referentiel.indicateur.ecartBudgetPct') },
  { value: 5, label: t('referentiel.indicateur.ecartN1') },
  { value: 6, label: t('referentiel.indicateur.ecartN1Pct') },
]

export const ecartsRef: OptionsCreator = (t) => [
  { value: 0, label: t('referentiel.ecart.budget') },
  { value: 1, label: t('referentiel.ecart.n1') },
]
