/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import moment from 'moment'
import { Input } from 'reactstrap'
import ReactTable from 'react-table-v6'
import { useTranslation } from 'react-i18next'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

import { useBudgetContext } from './BudgetContext'
import { usePees } from 'api'
import { EnergyIcon } from 'components/atoms'
import { decimalFormat } from 'helpers/formatter'
import { translations } from 'helpers/react-table'

const CustomInput: React.FC<any> = ({ onBlur, value, onChange, data, index }) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = React.useState<number>(value)

  React.useEffect(() => {
    const input = ref.current
    input?.focus()
    input?.addEventListener('blur', onBlur)
    return () => {
      input?.removeEventListener('blur', onBlur)
    }
  }, [onBlur])

  const { setValue } = useBudgetContext()
  const cb = debounce(setValue, 200)
  const debounced = React.useCallback(cb, [cb])

  return (
    <Input
      bsSize="sm"
      type="number"
      innerRef={ref}
      value={inputValue}
      onChange={(e) => {
        const newValue = Number(e.target.value)
        setInputValue(newValue)
        onChange(newValue)
        debounced(data.PeeId, data.ChrType, index, newValue)
      }}
    />
  )
}

const IndicatorCell = (props: any) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [edit, setEdit] = React.useState(false)
  const onFocus = () => setEdit(true)
  const onBlur = () => setEdit(false)

  React.useEffect(() => {
    const input = ref.current
    input?.addEventListener('focus', onFocus)
    return () => {
      input?.removeEventListener('focus', onFocus)
    }
  })

  return edit ? (
    <CustomInput
      {...{
        value: props.value.toFixed(3),
        onChange: (value: number) => props.onChange(props.index, props.column.index, value),
        onBlur,
        data: props.original,
        index: props.column.index,
      }}
    />
  ) : (
    <div
      tabIndex={0}
      ref={ref}
      style={{ textAlign: 'right', flex: 1, padding: '0 5px', cursor: 'default' }}
    >
      {decimalFormat(props.value, 2) || '-'}
    </div>
  )
}

const PeeCell = (props: any) => {
  const nrjId = props.pees?.[props.original.PeeId]?.PeeNrjId
  return (
    <div>
      <EnergyIcon id={nrjId} />
      {props.value}
    </div>
  )
}

export const BudgetTable: React.FC<{
  chroniques: Chronique[]
}> = ({ chroniques }) => {
  const { t } = useTranslation()
  const pees = usePees()

  const [data, setData] = React.useState(chroniques)

  React.useEffect(() => {
    if (chroniques) setData(chroniques)
  }, [chroniques])

  const onChange = React.useCallback(
    (peeIndex: number, monthIndex: number, value: number) => {
      const newData = cloneDeep(data)
      newData[peeIndex].Data[monthIndex].v = value
      setData(newData)
    },
    [data],
  )

  const months = React.useMemo(
    () => chroniques?.[0].Data.map(({ d }) => moment(d).format('MM/YY')) ?? [],
    [chroniques],
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('budget.site'),
        accessor: 'NomSite',
        width: 150,
        Cell: (props: any) => props.value,
      },
      {
        Header: t('budget.pdl'),
        accessor: 'PeeClef',
        width: 180,
        Cell: (props: any) => <PeeCell {...props} pees={pees} />,
      },
      ...months.map((month, index) => ({
        id: `month${index}`,
        index,
        Header: month,
        accessor: (d: any) => d.Data[index].v,
        Cell: (props: any) => <IndicatorCell {...props} onChange={onChange} />,
      })),
    ],
    [months, onChange, pees, t],
  )

  return (
    <ReactTable
      data={data}
      columns={columns}
      defaultPageSize={10}
      {...translations(t)}
      css={{
        fontSize: 13,
        marginBottom: '2rem',
        position: 'relative',
        width: '100%',
        '.rt-thead': {
          fontWeight: 'bold',
        },
        '.rt-td': {
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          height: 27.38,
        },
      }}
    />
  )
}
