import * as env from './env'
import axios from 'axios'

type Token = null | string

let token: Token = null
export const setToken: (t: Token) => void = (newToken) => (token = newToken)

let userPlatform = 'MUT'
export const setPlatform: (s: string) => void = (newPlatform) => (userPlatform = newPlatform)

/**
 * Wrapper d'appel HTTP pour gérer le cas de la déconnexion en 401 lors de l'expiration du cookie de refresh.
 * Le content-type est 'application/json'
 * Pour l'envoie de cookie le header 'credentials' est à 'include',
 *
 * @param {string} url Chemin relatif de l'API. Cf. <code>apiUrl</code> - Mandatory
 * @param {string} method Chemin relatif de l'API. Cf. <code>apiUrl</code> - Optional - Default 'GET'
 * @param {object} body Objet à serialiser dans le body - Optional - Default null
 * @param {string} uuid ID unique de la request - Optional - Default null
 * @param {string} contentType content type de la request - Optional - Default 'application/json'
 * @param {boolean} stringify Objet à serialiser dans le body - Optional - Default false
 */
export function superFetch({
  url,
  method = 'GET',
  body = null,
  uuid = null,
  contentType = 'application/json',
  stringify = true,
  platform,
  api,
  json = false,
  parse = true,
}: {
  url: string
  method?: any // string
  body?: any
  uuid?: null | string
  contentType?: string | null
  stringify?: boolean
  platform?: string
  api?: 'invoice'
  json?: boolean
  parse?: boolean
}): any {
  if (!url) throw new Error('Url est obligatoire pour le fetch wrapper')

  const source = axios.CancelToken.source()

  if (!platform) {
    platform = userPlatform
  }

  const baseUrl = api === 'invoice' ? env.apiUrlInvoice : env.apiUrl(platform)

  const headers = {
    ...(contentType && { 'Content-Type': contentType }),
    ...(uuid && { 'X-REQUEST-ID': uuid }),
    ...(token && { Authorization: `Bearer ${token}` }),
  }

  let request = null

  console.debug('superFetch : url: ' + url + ' method: ' + method + ' body: ', body)

  switch (method) {
    case 'POST':
    case 'PATCH':
    case 'PUT':
      request = axios({
        url: `${baseUrl}${url}`,
        withCredentials: true,
        method,
        data: body,
        headers,
        cancelToken: source.token,
      })
      break
    default:
      request = axios({
        url: `${baseUrl}${url}`,
        withCredentials: true,
        method,
        headers,
        cancelToken: source.token,
      })
  }

  const promise = parse ? request.then(({ data }) => data) : request
  ;(promise as any).cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }

  return promise
}
