/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import difference from 'lodash/difference'
import { FaTimes } from 'react-icons/fa'
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi'
import { lighten, desaturate } from 'polished'

import { RowProps, NodeProps, LeafProps } from './TreeSelect'
import { useStyle } from 'hooks'

const Leaf: React.FC<LeafProps> = ({ id, name, values, onChange }) => {
  const originalId = Number(id)
  const checked = React.useMemo(() => values.includes(originalId), [originalId, values])

  const toggle = React.useCallback(() => {
    if (!checked) {
      onChange([...values, originalId])
    } else {
      const i = values.findIndex((value) => value === originalId)
      if (i >= 0) {
        onChange([...values.slice(0, i), ...values.slice(i + 1)])
      }
    }
  }, [checked, onChange, originalId, values])

  const css = useStyle((theme: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px',
    background: desaturate(0.08, lighten(0.06, theme.blue)),
    fontSize: '0.9rem',
    width: '100%',
    height: '100%',
    color: theme.gray200,
    '>span': {
      flexShrink: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '>svg': {
        position: 'relative',
        top: 2,
      },
    },
    '>button': {
      color: theme.gray200,
      flexShrink: 0,
      background: 'transparent',
      border: 0,
      opacity: 0,
      transition: `opacity .4s ease`,
    },
    '&:hover>button': {
      opacity: 0.5,
    },
  }))

  return (
    <div css={css}>
      <span>{name}</span>
      <button onClick={toggle}>
        <FaTimes size={15} />
      </button>
    </div>
  )
}

const Node: React.FC<NodeProps> = ({ id, values, name, isOpen, toggle, nodes }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const leafs: any[] = nodes[id].leafs

  const diff = difference(leafs, values)
  const status = diff.length === 0 ? 1 : diff.length === leafs.length ? 0 : 0.5

  React.useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.indeterminate = status === 0.5
  }, [status])

  const css = useStyle((theme: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px',
    position: 'relative',
    fontSize: '0.9rem',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    color: theme.gray200,
    svg: {
      opacity: 0.5,
      flexShrink: 0,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      opacity: 0.1,
      borderTop: `1px solid ${theme.gray200}`,
    },
    '.node-name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1.25,
      paddingRight: '1rem',
      textAlign: 'left',
      userSelect: 'none',
    },
    '.node-collapse': {
      padding: `0 4px`,
    },
  }))

  return (
    <div onClick={toggle} color="light" css={css}>
      <span className="node-name">{name}</span>
      <span className="node-collapse">
        {isOpen ? <FiMinusCircle size={18} /> : <FiPlusCircle size={18} />}
      </span>
    </div>
  )
}

export const CollapseRow: React.FC<RowProps> = ({
  data: { id, isLeaf, name, values, onChange, nodes },
  isOpen,
  style,
  toggle,
}) => {
  const css = useStyle(() => ({
    ...style,
  }))

  return (
    <div css={css}>
      {isLeaf ? (
        <Leaf {...{ id, name, values, onChange }} />
      ) : (
        <Node {...{ id, name, values, isOpen, toggle, onChange, nodes }} />
      )}
    </div>
  )
}
