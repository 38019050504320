import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useSuiviMensuel } from 'api'
import { MensuelTable } from './MensuelTable'
import { getIndicators } from 'store/analyse'
import { suiviIndicatorsRef } from 'referentiel'

export const SuiviMensuelTable: React.FC = () => {
  const { t } = useTranslation()
  const data = useSuiviMensuel()
  const selectedIndicators = useSelector(getIndicators)

  if (!data) return null

  const indicators = suiviIndicatorsRef(t).filter((ind) => selectedIndicators.includes(ind.value))

  return (
    <MensuelTable
      data={data}
      indicators={indicators}
      striped
      hover
      filenamePrefix={t('suivi.filename')}
    />
  )
}
