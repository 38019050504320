import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useEcartsMensuels } from 'api'
import { getEcart } from 'store/analyse'
import { MensuelTable } from './MensuelTable'

export const EcartsMensuels: FC<{ peeId: number }> = ({ peeId, ...props }) => {
  const { t } = useTranslation()
  const data = useEcartsMensuels(peeId)

  const ecart = useSelector(getEcart)
  const isBudget = [0, 2].includes(ecart)
  const isPct = [2, 3].includes(ecart)

  if (!data) return null

  const indicators: any[] = [
    { value: isBudget ? 1 : 2, label: isBudget ? t('ecarts.budget') : t('ecarts.n1') },
    { value: 0, label: t('ecarts.reel') },
    {
      value: isBudget ? (isPct ? 4 : 3) : isPct ? 6 : 5,
      label: isBudget ? t('ecarts.ecartBudget') : t('ecarts.ecartN1'),
    },
    { value: isBudget ? 7 : 8, label: t('ecarts.impactPrix') },
    { value: isBudget ? 9 : 10, label: t('ecarts.impactVolume') },
  ]

  return (
    <MensuelTable
      data={data}
      indicators={indicators}
      filenamePrefix={t('ecarts.filenameMensuel')}
      {...props}
    />
  )
}
