import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import moment from 'moment'

import { dateFormat } from 'helpers/formatter'
import { getReady } from 'store/api'
import { getBudgetId, getVersionId } from 'store/budget'

export const useVersions = () => {
  const ready = useSelector(getReady)
  const budgetId = useSelector(getBudgetId)

  const { data } = useQuery<Version[], AnyQueryKey>(
    ready && budgetId !== null && ['versions', { budgetId }],
    () => superFetch({ url: `budgets/${budgetId}/reprevisions` }),
  )

  return data
}

export const useVersionName = () => {
  const versionId = useSelector(getVersionId)
  const versions = useVersions()
  const version = versions?.find((version) => version.Id === versionId)
  if (!version) return null
  return {
    date: moment(version.DateReprevision).format(dateFormat()),
    numero: version.Numero,
  }
}

export const useVersion = () => {
  const ready = useSelector(getReady)
  const budgetId = useSelector(getBudgetId)
  const versionId = useSelector(getVersionId)

  const { data } = useQuery<Version, AnyQueryKey>(
    ready && budgetId !== null && versionId !== null && ['version', { budgetId, versionId }],
    () => superFetch({ url: `budgets/${budgetId}/reprevisions/${versionId}` }),
  )

  return data
}

export const useCreateVersion = () => {
  const budgetId = useSelector(getBudgetId)

  return useMutation<Version, { date: string; chroniques: any }>(
    ({ date, chroniques }) =>
      superFetch({
        url: `budgets/${budgetId}/reprevisions`,
        method: 'POST',
        body: {
          DateReprevision: date,
          Chroniques: chroniques,
        },
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['versions', { budgetId }], { force: true })
      },
    },
  )
}

export const useDeleteVersion = () => {
  const budgetId = useSelector(getBudgetId)

  return useMutation<void, { versionId: number }>(
    ({ versionId }) =>
      superFetch({
        url: `budgets/${budgetId}/reprevisions/${versionId}`,
        method: 'DELETE',
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['versions', { budgetId }], { force: true })
      },
    },
  )
}

export const useUpdateVersion = () => {
  const budgetId = useSelector(getBudgetId)

  return useMutation<void, { version: Version }>(
    ({ version }) =>
      superFetch({
        url: `budgets/${budgetId}/reprevisions/${version.Id}`,
        method: 'PUT',
        body: version,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['versions', { budgetId }], { force: true })
      },
    },
  )
}
