import React from 'react'
import { AiOutlineWallet } from 'react-icons/ai'
import { FaChartArea, FaTruck, FaUniversity, FaDownload as DownIcon } from 'react-icons/fa'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import { superFetch } from 'commons'
import { Button, CustomInput, Card, CardHeader, CardBody, CardText, Table } from 'reactstrap'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import { useTranslation } from 'react-i18next'

import { addNotification } from 'notification'
import { useBudgetContext } from './BudgetContext'
import { ReactComponent as Elec } from 'images/elec.svg'
import { useBudgetName, useVersion } from 'api'
import { Section, Collapse } from 'components/atoms'
import { useStyle } from 'hooks'
import { BudgetTable } from './BudgetTable'
import { BudgetTableToggle } from './BudgetTableToggle'
import { downloadFile } from 'helpers/downloadFile'
import { getBudgetId, getVersionId } from 'store/budget'
import { dateFormat, noDecimalFormat } from 'helpers/formatter'

interface Tables {
  [chrType: number]: {
    label: string
    Icon: React.FC<any>
  }
}

export const BudgetTables: React.FC = () => {
  const { t } = useTranslation()
  const budgetId = useSelector(getBudgetId)
  const versionId = useSelector(getVersionId)

  const { synthese, setValues, setChroniques } = useBudgetContext()

  const version = useVersion()
  const budgetName = useBudgetName()
  const prevId = React.useRef<number | null>(null)
  const [csvData, setCsvData] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (!version || prevId.current === version.Id) return
    prevId.current = version.Id
    setChroniques(version.Chroniques)
  }, [setChroniques, version])

  const tables: Tables = {
    3000: { label: `${t('budget.volEnergie')} (MWh)`, Icon: FaChartArea },
    3001: { label: `${t('budget.coutEnergie')} (€)`, Icon: Elec },
    3002: { label: `${t('budget.taxes')} (€)`, Icon: FaUniversity },
    3003: { label: `${t('budget.acheminement')} (€)`, Icon: FaTruck },
    3004: { label: `${t('budget.autre')} (€)`, Icon: AiOutlineWallet },
  }

  const [chroniques, updateChroniques] = React.useState(
    Object.entries(groupBy(version?.Chroniques || [], 'ChrType')).map(([chrType, group]) => ({
      group,
      chrType: Number(chrType),
      ...tables[Number(chrType)],
    })),
  )

  React.useEffect(() => {
    if (!version) return
    const chroniques = Object.entries(groupBy(version?.Chroniques || [], 'ChrType')).map(
      ([chrType, group]) => ({
        group,
        chrType: Number(chrType),
        ...tables[Number(chrType)],
      }),
    )
    updateChroniques(chroniques)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  const [collapsed, setCollapsed] = React.useState<{ [index: number]: boolean }>(
    Object.keys(tables).reduce((acc, chrType) => ({ ...acc, [chrType]: true }), {}),
  )

  const toggle = React.useCallback(
    (index) => () => {
      setCollapsed((collapsed) => ({ ...collapsed, [index]: !collapsed[index] }))
    },
    [],
  )

  const style = useStyle((theme: any) => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1.5rem',
      h5: {
        lineHeight: 1.6,
        marginBottom: '1rem',
      },
    },
  }))

  if (!version) return null

  const date = moment(version.DateReprevision).format(dateFormat())

  return (
    <Section css={style}>
      <header>
        <Card>
          <CardHeader>
            <h5 className="m-0">
              <span>
                {t('budget.budget')} <strong>{budgetName}</strong>
              </span>
              <span style={{ margin: '0 .8rem' }}>&mdash;</span>
              <span>{t('budget.versionDu', { numero: version.Numero, date })}</span>
            </h5>
          </CardHeader>

          <CardBody>
            <CardText>
              <Table
                size="sm"
                className="m-0"
                css={{ 'tr:first-of-type': { 'th, td': { border: 'none' } } }}
              >
                <tbody>
                  <tr>
                    <th scope="row">{t('budget.synthese.pees')}</th>
                    <td>{noDecimalFormat(synthese.peeCount)}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('budget.synthese.totalVolume')}</th>
                    <td>{noDecimalFormat(synthese.totalVolume)} MWh</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('budget.synthese.energyExepense')}</th>
                    <td>{noDecimalFormat(synthese.energyExpense)} €</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('budget.synthese.taxesExpense')}</th>
                    <td>{noDecimalFormat(synthese.taxesExpense)} €</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('budget.synthese.gridExpense')}</th>
                    <td>{noDecimalFormat(synthese.gridExpense)} €</td>
                  </tr>
                  {/* <tr>
                    <th scope="row">{t('budget.synthese.miscExpense')}</th>
                    <td>{noDecimalFormat(synthese.miscExpense)} €</td>
                  </tr> */}
                  <tr>
                    <th scope="row">{t('budget.synthese.totalExpense')}</th>
                    <td>{noDecimalFormat(synthese.totalExpense)} €</td>
                  </tr>
                </tbody>
              </Table>
            </CardText>
          </CardBody>
        </Card>

        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            button: { display: 'flex', alignItems: 'center' },
            span: { marginLeft: '0.6rem' },
          }}
        >
          <div css={{ display: 'flex' }} className="mb-3">
            <CustomInput
              type="file"
              id="exampleCustomFileBrowser"
              name="customFile"
              label={t('budget.import.choisir')}
              css={{ width: 312 }}
              className="mr-2"
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (file) {
                  try {
                    const reader = new FileReader()
                    reader.onload = () => setCsvData(reader.result as string)
                    reader.readAsBinaryString(file)
                  } catch (error) {
                    addNotification({
                      type: 'danger',
                      title: t('budget.import.readError'),
                      message: (error as any).toString(),
                    })
                  }
                } else {
                  setCsvData(null)
                }
              }}
            />

            <Button
              style={{ zIndex: 100 }}
              onClick={() => {
                try {
                  const newChroniques = cloneDeep(chroniques)

                  const data = csvData
                    ?.split('\n')
                    .slice(2)
                    .filter((line) => line.length > 4)
                    .map((line) => line.split(';'))

                  const operations: any[] = []

                  data?.forEach((line, peeIndex) => {
                    const peeId = Number(line[0])
                    line.slice(4).forEach((cell, index) => {
                      const n = parseFloat(cell.replace(',', '.'))
                      const value = isNaN(n) ? 0 : n

                      const chrTypeIndex = Math.floor(index / 12)
                      if (chrTypeIndex > 4) return

                      const monthIndex = index - 12 * chrTypeIndex

                      newChroniques[chrTypeIndex].group[peeIndex].Data[monthIndex].v = value

                      const chrType = [3000, 3001, 3002, 3003, 3004][chrTypeIndex]
                      operations.push({ peeId, chrType, monthIndex, value })
                    })
                  })

                  setValues(operations)

                  updateChroniques(newChroniques)

                  addNotification({
                    type: 'success',
                    message: t('budget.import.uploadSuccess'),
                  })
                } catch (error) {
                  addNotification({
                    type: 'danger',
                    title: t('budget.import.uploadErrorTitle'),
                    message: t('global.error'),
                  })
                }
              }}
              disabled={csvData === null}
            >
              {t('budget.import.uploadTitle')}
            </Button>
          </div>

          <Button
            onClick={async () => {
              try {
                const resp = await superFetch({
                  url: `budgets/${budgetId}/reprevisions/${versionId}/template`,
                  parse: false,
                })

                // Get filename from headers
                const disposition = resp.headers['content-disposition']
                const filenameRx = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                const match = filenameRx.exec(disposition)
                const filename = (match && match[1]) || 'template.csv'

                const data = (resp.data as string)
                  .split('\n')
                  .map((line, i) => {
                    if (i < 2) return line
                    const cells = line.split(';')
                    if (cells.length > 2) cells[2] = `"=""${cells[2]}"""`
                    return cells.join(';')
                  })
                  .join('\n')

                downloadFile(filename, data, { type: 'text/csv' })
              } catch (error) {
                addNotification({
                  type: 'danger',
                  title: t('budget.import.downloadError'),
                  message: (error as any).toString(),
                })
              }
            }}
          >
            <DownIcon />
            <span>{t('budget.import.downloadTitle')}</span>
          </Button>
        </div>
      </header>

      {chroniques.map(({ label, Icon, group, chrType }, index) => {
        const isOpen = !collapsed[chrType]
        return (
          <div key={label}>
            <BudgetTableToggle
              tabIndex={index}
              toggle={toggle(chrType)}
              {...{ isOpen, label, Icon }}
            />

            <Collapse isOpen={isOpen}>
              <BudgetTable chroniques={group} />
            </Collapse>
          </div>
        )
      })}
    </Section>
  )
}
